/* ========
detail-info
======== */

.detail-info{
  padding: 100px 0;
  @include mq(sp) {
    padding: 40px 0;
  }
  .l-inside{
    padding: 0 40px;
    @include mq(sp) {
      padding: 0 $sp-padding;
    }
  }
  &__meta{
    display: flex;
    align-items: center;
  }
  &__date{
    font-size: 1.3rem;
    letter-spacing: .06em;
  }
  &__tag{
    width: 140px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    background-color: #a60019;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: .06em;
    margin: 0 0 0 1em;
  }
  &__ttl{
    font-size: 2.9rem;
    font-weight: 700;
    letter-spacing: .06em;
    border-bottom: 2px solid palettes(main);
    line-height: 1.5;
    padding: 8px 0 16px;
    @include mq(sp) {
      font-size: 2rem;
    }
  }
  &__content{
    padding: 20px 60px 40px;
    text-align: justify;
    @include mq(sp) {
      padding: 16px 0 32px;
    }
    h1,h2{
      font-size: 2.3rem;
      font-weight: 500;
      letter-spacing: .06em;
      border-left: 3px solid palettes(main);
      line-height: 1.4;
      padding: 0 0 0 .75em;
      margin: 2em 0 1em;
      @include mq(sp) {
        font-size: 1.8rem;
      }
    }
    h3{
      font-size: 1.8rem;
      font-weight: 500;
      letter-spacing: .06em;
      color: palettes(main);
      line-height: 1.7;
      margin: 1em 0;
      @include mq(sp) {
        font-size: 1.6rem;
      }
    }
    h4{
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: .06em;
      @include mq(sp) {
        font-size: 1.5rem;
      }
    }
    h5{
      font-size: 1.5rem;
      font-weight: 500;
      letter-spacing: .06em;
      @include mq(sp) {
        font-size: 1.4rem;
      }
    }
    h6{
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: .06em;
      @include mq(sp) {
        font-size: 1.3rem;
      }
    }
    i,em{
      font-family: $serif;
    }
    ul{
      list-style-type: disc;
      padding: 0 0 0 2em;
      margin: 1em 0;
      li{
        list-style-type: disc;
      }
    }
    ol{
      list-style-type: decimal;
      padding: 0 0 0 2em;
      margin: 1em 0;
      li{
        list-style-type: decimal;
      }
    }
    a{
      // text-decoration: underline;
      color: #0064b4;
      @include mq(pc) {
        &:hover{
          opacity: 1;
          text-decoration: underline;
        }
      }
    }
    p{
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: .06em;
      line-height: 1.8;
      margin: 1em 0;
    }
    blockquote{
      color: #505050;
      background-color: #f6f6f6;
      padding: 24px 32px;
      margin: 10px 0;
      @include mq(sp) {
        padding: 16px;
      }
    }
  }
  &__thumb{
    margin: 0 0 56px;
    @include mq(sp) {
      margin: 0 0 32px;
    }
  }
  &__btns{
    display: flex;
    justify-content: center;
    @include mq(sp) {
      display: block;
    }
    .btn-basic{
      width: 340px;
      margin: 0 10px;
      @include mq(sp) {
        width: 100%;
        max-width: 340px;
        margin: 0 auto 10px;
      }
    }
  }
  &__share{
    background-color: #f6f6f6;
    border: 1px solid #ececec;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 64px 0 32px;
    @include mq(sp) {
      margin: 32px 0 24px;
      padding: 8px;
    }
    &__label{
      display: flex;
      align-items: center;
      padding: 0 0 0 5px;
      figure{
        display: block;
        width: 20px;
        margin: 0 10px 0 0;
        @include mq(sp) {
          width: 16px;
        }
      }
      p{
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: .06em;
        @include mq(sp) {
          font-size: 1.4rem;
        }
      }
    }
    &__links{
      display: flex;
      a{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background-color: #ececec;
        margin: 0 0 0 10px;
        @include mq(sp) {
          width: 32px;
          height: 32px;
          margin: 0 0 0 8px;
        }
        &.-facebook{
          background-color: #0050a0;
          img{
            width: 32%;
          }
        }
        &.-twitter{
          background-color: #00a0f0;
          img{
            width: 50%;
          }
        }
      }
    }
  }
  &__back{
    width: 180px;
    height: 50px;
    margin: 0 auto;
    @include mq(sp) {
      font-size: 180px;
      height: 40px;
    }
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: #6e7882;
      color: #fff;
      font-size: 1.8rem;
      font-weight: 500;
      letter-spacing: .06em;
      @include mq(sp) {
        font-size: 1.6rem;
      }
    }
  }
}