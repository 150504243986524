/* ========
item-info
======== */

.item-info{
  a{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 24px 0;
    @include mq(sp) {
      display: block;
      padding: 16px 5px 16px 10px;
    }
  }
  &__meta{
    display: flex;
    align-items: center;
    margin: 0 20px 0 0;
    @include mq(sp) {
      margin: 0 0 8px;
    }
  }
  &__date{
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: .06em;
    width: 94px;

  }
  &__tag{
    width: 110px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    background-color: palettes(main);
    color: #fff;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: .06em;
    @include mq(sp) {
      height: 25px;
      font-size: 1.2rem;
    }
  }
  &__ttl{
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .06em;
    @include mq(sp) {
      line-height: 1.5;
    }
  }
}