/* ========
unit-contact
======== */

.unit-contact{
  padding: 80px 0;
  @include mq(sp) {
    padding: 40px 0;
  }
  .l-inside{
    padding: 0 40px;
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: .06em;
    @include mq(sp) {
      padding: 0 $sp-padding;
    }
  }
}