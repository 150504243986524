/* ========
unit-col2box
======== */

.unit-col2box{
  display: flex;
  justify-content: space-between;
  background-color: #e6e6e6;
  padding: 20px;
  @include mq(sp) {
    padding: 10px;
    display: block;
  }
  > * {
    width: calc(50% - 10px);
    flex: 0 0 auto;
    background-color: #fff;
    padding: 50px 0;
    @include mq(sp) {
      width: 100%;
      margin-top: 10px;
      padding: 20px 0;
      &:first-child{
        margin-top: 0;
      }
    }
  }
}