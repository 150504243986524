/* ========
list-info
======== */

.list-info{
  padding: 100px 0;
  @include mq(sp) {
    padding: 40px 0;
  }
  .l-inside{
    padding: 0 40px;
    @include mq(sp) {
      padding: 0 $sp-padding;
    }
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    .item-note{
      @include mq(pc) {
        width: 445px;
        margin: 20px 0 0;
        &:nth-child(2n){
          margin-left: 20px;
        }
      }
      @include mq(sp) {
        max-width: 445px;
        width: calc(100% - 16px);
        margin: 0 auto 20px;
      }
    }
    .item-info{
      width: 100%;
      border-bottom: 1px solid #d2d7dc;
    }
  }
}