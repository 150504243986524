/* ========
unit-pickup
======== */

.unit-pickup{
  padding: 80px 0;
  background-color: #e6e6e6;
  @include mq(sp) {
    padding: 40px 0;
  }
  .ttl-type02{
    margin: 0 0 32px;
  }
  &__content{
    display: flex;
    justify-content: center;
    @include mq(sp) {
      display: block;
      padding: 0 $sp-padding;
    }
    .item-note{
      width: 445px;
      margin: 0 10px;
      @include mq(sp) {
        width: 100%;
        max-width: 445px;
        margin: 0 auto 10px;
      }
    }
  }
}