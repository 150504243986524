/* ========
area-hero
======== */

.area-hero{
  height: 440px;
  background: #5a646d center center no-repeat;
  background-size: cover;
  @include mq(sp) {
    height: 220px;
  }
  .l-main{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    @include mq(sp) {
      padding: 0 0 16px;
    }
  }
  &__ttl{
    color: #fff;
    line-height: 1.2;
    text-align: center;
    &__ja{
      font-family: $serif;
      letter-spacing: .1em;
      font-size: 5.4rem;
      font-weight: 600;
      @include mq(sp) {
        font-size: 3.2rem;
      }
    }
    &__en{
      font-family: $century;
      font-size: 2.1rem;
      letter-spacing: .1em;
      font-weight: 400;
      margin: 24px 0 0;
      @include mq(sp) {
        font-size: 1.7rem;
        margin: 12px 0 0;
      }
    }
  }
}