/* ========
unit-school-link
======== */

.unit-school-link{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @include mq(sp) {
    display: block;
    margin-top: $sp-padding;
  }
  &__box{
    width: calc(50% - 10px);
    background-color: #fff;
    text-align: center;
    padding: 88px 0;
    @include mq(sp) {
      // text-align: left;
      display: block;
      width: 100%;
      padding: 24px $sp-padding;
      &:nth-child(n+2){
        margin-top: $sp-padding;
      }
    }
  }
  &__logo{
    width: 118px;
    height: 110px;
    margin: 0 auto 24px;
    @include mq(sp) {
      width: 96px;
      height: auto;
      margin: 0 auto 16px;
    }
  }
  &__ttl{
    text-align: center;
    &__ja{
      font-family: $serif;
      font-size: 2.4rem;
      font-weight: 600;
      letter-spacing: .1em;
      line-height: 1.4;
      @include mq(sp) {
        font-size: 2rem;
      }
    }
    &__en{
      font-family: $century;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.4;
      letter-spacing: .08em;
      color: palettes(main);
      margin: 8px 0 0;
      @include mq(sp) {
        font-size: 1.4rem;
      }
    }
  }
  .btn-basic{
    margin-top: 24px;
  }
}