/* ========
lead-case-study
======== */

.lead-case-study{
  padding: 80px 0;
  @include mq(sp) {
    padding: 40px $sp-padding;
  }
  .l-inside{
    >.btn-basic{
      margin-top: 10px;
    }
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    padding: 0 30px;
    @include mq(sp) {
      padding: 0;
    }
    .item-note{
      margin: 0 10px 20px;
      @include mq(pc) {
        width: 445px;
      }
      @include mq(sp) {
        max-width: 445px;
        width: calc(100% - 16px);
        margin: 0 auto 20px;
      }
    }
  }
}