/* ========
unit-mission
======== */

.unit-mission{
  padding: 80px 0 90px;
  position: relative;
  z-index: 0;
  @include mq(sp) {
    padding: 40px 0;
  }
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    min-width: $pc-min-width;
    background-color: #00192d;
    z-index: -1;
  }
  .ttl-type02{
    margin-bottom: 32px;
  }
  .ttl-type03{
    &:after{
      content: none;
    }
  }
  &__col2{
    display: flex;
    justify-content: space-between;
    margin: 40px 0 0;
    @include mq(sp) {
      display: block;
      padding: 0 $sp-padding;
    }
    .unit-mission__box{
      width: calc(50% - 10px);
      @include mq(sp) {
        width: 100%;
      }
    }
  }
  &__col1{
    display: block;
    margin: 20px 0 0;
    @include mq(sp) {
      margin: 0;
      padding: 0 $sp-padding;
    }
  }
  &__ttl{
    color: #00192d;
    text-align: center;
    margin: 0 0 24px;
    &__en{
      font-family: $century;
      font-size: 4.3rem;
      font-weight: 500;
      letter-spacing: .08em;
      line-height: 1.2;
      @include mq(sp) {
        font-size: 3.6rem;
      }
    }
    &__ja{
      font-family: $serif;
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: .1em;
      line-height: 1.2;
      @include mq(sp) {
        font-size: 1.3rem;
      }
    }
  }
  &__box{
    background-color: #fff;
    padding: 40px 36px 56px;
    @include mq(sp) {
      padding: 32px 20px;
      margin-top: $sp-padding;
    }
  }
  &__list{
    counter-reset: listNum;
    &.-one{
      li{
        margin-left: 2.15em;
        text-indent: -2.15em;
        &:before{
          content: 'ー、';
          font-weight: 400;
        }
      }
    }
    li{
      font-size: 1.5rem;
      font-weight: 500;
      letter-spacing: .06em;
      line-height: 1.55;
      margin-left: 1.4em;
      text-indent: -1.4em;
      @include mq(sp) {
        font-size: 1.4rem;
      }
      &:nth-child(n+2){
        margin-top: 0.66em;
      }
      &:before{
        counter-increment: listNum 1;
        content: counter(listNum)'. ';
        font-weight: 700;
        color: palettes(main);
      }
    }
  }
}