/* ========
top
======== */

.page-thanks{
  .unit-text-base{
    @include mq(pc) {
      min-height: calc(100vh - 440px);
    }
  }
}