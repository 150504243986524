/* ========
button
======== */

.btn-basic{
  width: 465px;
  height: 60px;
  @include mq(sp) {
    width: 100%;
  }
  a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-family: $century;
    font-size: 2.1rem;
    color: #fff;
    font-weight: 400;
    letter-spacing: .1em;
    line-height: 1.2;
    position: relative;
    z-index: 0;
    overflow: hidden;
    background-color: palettes(main);
    @include mq(pc) {
      &:hover{
        opacity: 1;
        &:before{
          right: 50px;
        }
      }
    }
    span{
      font-family: $serif;
      font-size: 1.3rem;
      font-weight: 400;
      letter-spacing: .06em;
      display: block;
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      transform-origin: right bottom;
      z-index: -1;
      background-color: palettes(main,dark);
      transform: translate(100%,0)skew(-45deg);
      right: 30px;
      transition: .4s ease;
    }
    &[target="_blank"]{
      font-family: $serif;
      display: flex;
      flex-direction: row;
      font-size: 1.5rem;
      &:after{
        content: '';
        display: block;
        width: 1em;
        height: 1em;
        background: transparent center center no-repeat;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512.00098 512'%3E%3Cdefs/%3E%3Cstyle%3E.st0{fill:%23fff}%3C/style%3E%3Cpath d='M488.72699 0H302.54501c-12.853 0-23.27301 10.42-23.27301 23.273s10.42001 23.273 23.27301 23.273h129.99698l-239.543 239.544c-9.089 9.08899-9.089 23.823 0 32.91199 4.543 4.54401 10.49899 6.81601 16.455 6.81601 5.95599 0 11.91299-2.271 16.457-6.81699L465.45499 79.458v129.99698c0 12.853 10.42001 23.27299 23.27301 23.27299s23.27298-10.42 23.27298-23.27299V23.273C512 10.42 501.57999 0 488.72699 0z' class='st0'/%3E%3Cpath d='M395.63599 232.72701c-12.853 0-23.27301 10.42-23.27301 23.27299v209.45502H46.545V139.636H256c12.853 0 23.27301-10.42 23.27301-23.273S268.853 93.091 256 93.091H23.273C10.42 93.091 0 103.511 0 116.364v372.36401C0 501.57999 10.42 512 23.273 512h372.36401c12.853 0 23.27301-10.42001 23.27301-23.27301V256c-.00102-12.853-10.42101-23.27299-23.27403-23.27299z' class='st0'/%3E%3C/svg%3E");        
        background-size: 80%;
        margin: 0 0 0 .25em;
      }
    }
  }


  // lang
  &.-ja{
    a{
      font-family: $gothic;
      font-size: 1.6rem;
    }
  }

  // size
  &.-row2{
    height: 80px;
  }

  // color
  &.-ghost{
    a{
      background-color: transparent;
      border: 1px solid #fff;
      &:before{
        background-color: #fff;
      }
    }
    &.-black{
      a{
        color: #000;
        border-color: #000;
        &:before{
          background-color: #000;
        }
      }
    }
  }
}


.btn-menu{
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 60px;
  background-color: #780000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span{
    display: block;
    width: 30px;
    height: 2px;
    background-color: #fff;
    position: relative;
    &:before, &:after{
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      background-color: #fff;
    }
    &:before{
      top: -8px;
    }
    &:after{
      bottom: -8px;
    }
  }
}