/* ========
top
======== */

.home{
  .gnav{
    &__logo{
      @include mq(sp) {
        width: 150px;
        height: 150px;
      }
    }
  }
}