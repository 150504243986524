/* ========
unit-access
======== */

.unit-access{
  padding: 80px 20px 90px;
  position: relative;
  z-index: 0;
  @include mq(sp) {
    padding: 40px $sp-padding;
  }
  .ttl-type02{
    margin-bottom: 32px;
  }
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    min-width: $pc-min-width;
    background-color: #e6e6e6;
    z-index: -1;
  }
  &__col{
    display: flex;
    justify-content: space-between;
    @include mq(sp) {
      display: block;
    }
  }
  &__box{
    width: calc(50% - 10px);
    background-color: #fff;
    @include mq(sp) {
      width: 100%;
      margin-top: $sp-padding;
    }
  }
  &__map{
    iframe{
      display: block;
      width: 100%;
      height: 390px;
      @include mq(sp) {
        height: 220px;
      }
    }
  }
  &__textarea{
    padding: 32px;
    text-align: center;
    @include mq(sp) {
      padding: 24px;
    }
  }
  &__name{
    font-family: $serif;
    font-size: 2.4rem;
    font-weight: 600;
    letter-spacing: .1em;
    line-height: 1.4;
    margin: 0 0 16px;
    @include mq(sp) {
      font-size: 2rem;
      margin: 0 0 8px;
    }
  }
  &__desc{
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: .06em;
    line-height: 2;
  }
}