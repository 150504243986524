/* ========
box-service-detail
======== */

.box-service-detail{
  &.-reverse{
    .box-service-detail__main{
      flex-direction: row-reverse;
    }
  }
  &.-slim{
    .box-service-detail__main{
      &__textarea{
        @include mq(pc) {
          padding: 48px 0;
        }
      }
    }
  }
  &__main{
    display: flex;
    @include mq(sp) {
      display: block;
    }
    &__ph{
      width: 100%;
      background: transparent center center no-repeat;
      background-size: cover;
      @include mq(sp) {
        height: 42vw;
      }
    }
    &__textarea{
      width: 565px;
      padding: 112px 0;
      flex: 0 0 auto;
      text-align: center;
      background-color: #fff;
      @include mq(sp) {
        width: 100%;
        padding: 24px $sp-padding;
      }
      .desc-s{
        @include mq(sp) {
          text-align: justify;
        }
      }
    }
  }
  &__num{
    font-family: $century;
    font-size: 2rem;
    font-weight: 500;
    color: palettes(main);
    letter-spacing: .08em;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.4;
    margin: 0 0 24px;
    @include mq(sp) {
      margin: 0 0 16px;
    }
    &:after{
      font-family: $serif;
      counter-increment: serviceDetail 1;
      content: counter(serviceDetail,decimal-leading-zero);
      font-size: 3.3rem;
      margin: -6px 0 0 .25em;
    }
  }
  &__ttl{
    font-family: $serif;
    font-weight: 600;
    font-size: 2.6rem;
    letter-spacing: .1em;
    line-height: 1.2;
    @include mq(sp) {
      font-size: 2rem;
    }
  }
  &__sub{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #f6f6f6;
    padding: 20px 15px;
    @include mq(sp) {
      display: block;
      padding: $sp-padding;
    }
    &__box{
      display: flex;
      background-color: #fff;
      width: calc(50% - 5px);
      margin: 0 5px;
      @include mq(sp) {
        width: 100%;
        margin: 0;
        display: block;
        &:nth-child(n+2){
          margin-top: $sp-padding;
        }
      }
    }
    &__ph{
      width: 260px;
      min-height: 240px;
      background: transparent center center no-repeat;
      background-size: cover;
      flex: 0 0 auto;
      @include mq(sp) {
        width: 100%;
        min-height: 160px;
      }
    }
    &__textarea{
      padding: 16px 20px;
      text-align: justify;
    }
    &__list{
      width: 100%;
      ul,ol{
        width: 100%;
        display: flex;
        justify-content: space-between;
        @include mq(sp) {
          display: block;
        }
      }
      li{
        width: calc(33.333% - 13.333px);
        background-color: #fff;
        padding: 16px 24px;
        margin: 0 5px;
        flex: 1 1 auto;
        @include mq(sp) {
          width: 100%;
          margin: 0;
          &:nth-child(n+2){
            margin-top: $sp-padding;
          }
        }
      }
    }
  }
  &__copy{
    font-family: $serif;
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: .1em;
    line-height: 1.7;
    @include mq(sp) {
      font-size: 1.6rem;
    }
  }
  .desc-xs{
    span{
      font-size: 1.1rem;
    }
    a{
      text-decoration: underline;
    }
  }
}