/* ========
gnav
======== */

.gnav{
  height: 100%;
  background-color: palettes(main);
  padding: 0 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  @include mq(pc) {
    // min-height: 790px;
  }
  @include mq(sp) {
    height: 60px;
  }
  &__logo{
    width: calc(100% - 10px);
    background-color: #fff;
    padding: 35px 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq(pc) {
      &:hover{
        opacity: 1;
        img{
          opacity: .6;
        }
      }
    }
    @include mq(sp) {
      width: 90px;
      height: 90px;
      padding: 0;
      transition: .4s ease;
    }
    img{
      @include mq(pc) {
        width: 100%;
        transition: .4s ease;
      }
      @include mq(sp) {
        width: 66%;
      }
    }
  }
  &__nav{
    margin: 10px 0 0;
    @include mq(sp) {
      display: none;
      padding: 60px 0 24px;
      border-bottom: 1px solid rgba(#fff,.15);
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: rgba(#00192d,.85);
      overflow-y: scroll;
      max-height: 100vh;
      &:before{
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        border-top: 60px solid palettes(main);
      }
    }
    ul{
    }
    li{
      &:after{
        content: '';
        display: block;
        border-top: 1px solid #870000;
        border-bottom: 1px solid #b41428;
        @include mq(sp) {
          border-top-color: rgba(#fff,.15);
          border-bottom: 0;
        }
      }
      >a{
        color: #fff;
        display: block;
        text-align: center;
        padding: 10px 0 16px;
        transition: .4s ease;
        @include mq(pc) {
          &:hover{
            opacity: 1;
            background-color: #870000;
          }
        }
        span{
          display: block;
          line-height: 1.333;
          &.-en{
            font-family: $century;
            font-size: 2.1rem;
            letter-spacing: .12em;
            font-weight: 400;
          }
          &.-ja{
            font-family: $serif;
            font-size: 1.1rem;
            letter-spacing: .12em;
            font-weight: 500;
          }
        }
      }
    }
    .btn-basic{
      @include mq(sp) {
        width: calc(100% - 50px);
        margin: 24px auto 16px;
        a{
          font-family: $gothic;
          font-size: 1.6rem;
        }
      }
    }
    &__facebook{
      width: 40px;
      margin: 0 auto;
    }
    &__sub{
      position: absolute;
      top: 0;
      left: 100%;
      right: 0;
      bottom: 0;
      width: 340px;
      background-color: rgba(#191e23,.9);
      padding: 30px 10px;
      display: none;
      @include mq(sp) {
        display: none !important;
      }
      &__ttl{
        line-height: 1;
        margin: 0 0 16px;
        span{
          text-align: center;
          color: #fff;
          display: block;
          &.-en{
            font-family: $century;
            font-size: 3.8rem;
            letter-spacing: .08em;
            font-weight: 500;
            line-height: 1;
          }
          &.-ja{
            font-size: $serif;
            font-size: 1.4rem;
            font-weight: 500;
            letter-spacing: .12em;
          }
        }
      }
      &__links{
        a{
          display: block;
          padding: 0;
          margin: 0 0 10px;
          position: relative;
          z-index: 0;
          overflow: hidden;
          img{
            position: relative;
            z-index: 0;
            margin-top: -32px;
          }
        }
        p{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 72px;
          background-color: #fff;
          color: #000;
          font-family: $serif;
          font-size: 1.8rem;
          line-height: 1;
          letter-spacing: .1em;
          text-align: center;
          font-weight: 600;
          margin-top: -32px;
          position: relative;
          z-index: 1;
          span{
            font-family: $century;
            display: block;
            line-height: 1;
            color: palettes(main);
            font-size: 1.4rem;
            font-weight: 500;
            margin: 4px 0 0;
          }
        }
      }
    }
  }
  &__contact{
    width: calc(100% - 20px);
    margin: 10px auto 0;
    @include mq(sp) {
      display: none;
    }
    a{
      display: block;
      background-color: palettes(main,dark);
      color: #fff;
      text-align: center;
      padding: 16px 0;
      @include mq(pc) {
        &:hover{
          opacity: 1;
          background-color: #870000;
        }
      }
      span{
        display: block;
        line-height: 1.2;
        &.-en{
          font-family: $century;
          font-size: 1.8rem;
          letter-spacing: .1em;
          margin: 8px 0 0;
        }
        &.-ja{
          font-family: $serif;
          font-size: 1rem;
          letter-spacing: .12em;
        }
      }
    }
    img{
      width: 33px;
      margin: 0 auto;
    }
  }
  &__facebook{
    display: flex;
    justify-content: center;
    margin: 16px 0;
    @include mq(sp) {
      display: none;
    }
    a{
      display: block;
      padding: 4px 12px;
      img{
        width: 14px;
      }
    }
  }
  .copyright{
    font-size: 1rem;
    color: #fff;
    text-align: center;
    font-weight: 400;
    letter-spacing: .06em;
    line-height: 1.7;
    @include mq(sp) {
      display: none;
    }
  }


  &.-slim{
    .gnav__logo{
      padding: 18px 38px;
      img{
        width: 88%;
      }
    }
    .gnav__nav{
      li{
        >a{
          padding: 4px 0 10px;
          span{
            line-height: 1.24;
          }
        }
      }
    }
    .gnav__contact{
      a{
        padding: 10px 0 8px;
        img{
          width: 28px;
        }
        span{
          &.-ja{
            display: none;
          }
          &.-en{
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}