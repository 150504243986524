/*--------------------------------
prlx-view
-----------------------------------*/
.prlx-view{
  &[data-prlx="fade"]{
    opacity: 0;
  }
  &[data-prlx="fade-to-up"]{
    transform: translate(0, 40px);
    opacity: 0;
  }
  &[data-prlx="fade-to-left"]{
    transform: translate(60px, 0);
    opacity: 0;
  }
  &[data-prlx="fade-to-right"]{
    transform: translate(-60px, 0);
    opacity: 0;
  }

  // -animated
  &.-animated{
    &[data-prlx="fade"]{
      opacity: 1;
      transition: .7s cubic-bezier(.43,.88,.65,1);
    }
    &[data-prlx="fade-to-up"]{
      transition: .7s cubic-bezier(.43,.88,.65,1);
      transform: translate(0, 0);
      opacity: 1;
    }
    &[data-prlx="fade-to-left"]{
      transition: .7s cubic-bezier(.43,.88,.65,1);
      transform: translate(0, 0);
      opacity: 1;
    }
    &[data-prlx="fade-to-right"]{
      transition: .7s cubic-bezier(.43,.88,.65,1);
      transform: translate(0, 0);
      opacity: 1;
    }
  }
}