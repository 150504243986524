/* ========
breadcrumbs
======== */

.breadcrumbs{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px 24px;
  display: flex;
  @include mq(sp) {
    padding: 4px 16px;
  }
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    min-width: $pc-min-width;
    background-color: rgba(#00192d,.2);
    z-index: -1;
  }
  span,a{
    color: #fff;
    font-size: 1.3rem;
    letter-spacing: .06em;
    font-weight: 400;
    @include mq(sp) {
      font-size: 1.2rem;
    }
  }
  >span{
    &:nth-child(n+2){
      &:before{
        content: '―';
        margin: 0 1em;
      }
    }
  }
}