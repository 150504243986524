/* ========
unit-greeting
======== */

.unit-greeting{
  &__head{
    position: relative;
    z-index: 0;
    padding: 96px 0 196px;
    @include mq(sp) {
      padding: 56px 0 88px;
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -210px;
      right: -210px;
      bottom: 0;
      background: transparent url(../images/company/img_company01.jpg) center center no-repeat;
      background-size: cover;
      z-index: -1;
    }
    .ttl-type03{
      color: #fff;
      &:after{
        content: none;
      }
    }
  }
  &__ttl{
    color: #fff;
    text-align: center;
    &:after{
      content: '';
      display: block;
      width: 3px;
      height: 20px;
      background-color: #fff;
      margin: 16px auto 12px;
      @include mq(sp) {
        width: 2px;
        height: 16px;
      }
    }
    &__en{
      font-family: $century;
      font-size: 5rem;
      font-weight: 500;
      letter-spacing: .08em;
      line-height: 1.2;
      @include mq(sp) {
        font-size: 3.6rem;
      }
    }
    &__ja{
      font-family: $serif;
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: .1em;
      line-height: 1.2;
    }
  }
  &__main{
    padding: 20px;
    background-color: #e6e6e6;
    @include mq(sp) {
      padding: $sp-padding;
    }
  }
  &__content{
    background-color: #fff;
    padding: 56px 0;
    position: relative;
    z-index: 1;
    margin-top: -120px;
    @include mq(sp) {
      text-align: left;
      margin-top: -72px;
      padding: 20px;
    }
    .l-inside{
      display: flex;
      @include mq(sp) {
        display: block;
      }
    }
  }
  &__ph{
    width: 340px;
    margin: 0 40px 0 0;
    flex: 0 0 auto;
    @include mq(sp) {
      max-width: 100%;
      width: 100%;
      margin: 0 auto 32px;
      img{
        margin: 0 auto;
      }
    }
  }
  &__textarea{
    // text-align: center;
    .desc-m{
      line-height: 1.9;
      &:first-child{
        margin-top: 0;
      }
      text-indent: 1em;
    }
  }
  &__name{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: .06em;
    margin-top: 32px;
    img{
      font-family: $serif;
      font-size: 3.4rem;
      font-weight: 700;
      line-height: 1;
      height: 1em;
      margin-left: .5em;
      @include mq(sp) {
        font-size: 2.8rem;
      }
    }
  }
}