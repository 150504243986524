/* ========
.layout
======== */
.l-inside {
  max-width: $pc-min-width - $pc-side-width;
  margin: 0 auto;
  @include mq(sp){
    max-width: 100%;
  }
}
.l-inner {
  min-width: $pc-min-width - $pc-side-width;
  padding: 0 $pc-padding;
  margin: 0 auto;
  @include mq(sp){
    min-width: 0;
    padding: 0 $sp-padding;
  }
}

.l-wrapper {
  position: relative;
  overflow: hidden;
  @include mq(sp) {
    padding: 60px 0 0;
  }
}

.l-main{
  @include mq(pc) {
    margin-left: $pc-side-width;
  }
}