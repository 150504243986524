/* ========
tab-link
======== */

.tab-link{
  ul{
    display: flex;
    padding: 0 0 2px;
    border-bottom: 2px solid palettes(main);
    height: 64px;
    @include mq(sp) {
      height: 54px;
    }
  }
  li{
    width: 260px;
    // height: 60px;
    margin: 0 5px 0 0;
    @include mq(sp) {
      width: 110px;
      height: 50px;
    }
    &.-current{
      height: 62px;
      @include mq(sp) {
        height: 52px;
      }
      a{
        background-color: palettes(main);
      }
    }
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #fff;
      font-size: 1.8rem;
      font-weight: 500;
      letter-spacing: .06em;
      background-color: #6e7882;
      @include mq(sp) {
        font-size: 1.3rem;
        text-align: center;
        line-height: 1.4;
        padding: 2px 4px;
      }
    }
  }
}