/* ========
l-header
======== */
.l-header {
  position: fixed;
  padding: 40px 0 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 210px;
  z-index: 999;
  @include mq(sp) {
    width: auto;
    padding: 0;
    bottom: auto;
    right: 0;
  }
}