/* ========
foot-nav
======== */

.foot-nav{
  &__nav{
    background-color: #00192d;
    color: #fff;
    padding: 56px 0;
    display: flex;
    justify-content: center;
    @include mq(sp) {
      flex-wrap: wrap;
      padding: 0;
    }
    &__grp{
      display: flex;
      justify-content: center;
      @include mq(sp) {
        width: 100%;
        margin: 0;
        text-align: center;
        border-bottom: 1px solid #1e3246;
      }
      &.-service{
        display: block;
        margin: 0 28px;
        @include mq(sp) {
          padding: 12px 0 16px;
        }
        > .foot-nav__nav__link{
          margin: 0;
          @include mq(sp) {
            width: 100%;
            height: auto;
          }
        }
        @include mq(sp) {
          width: 100%;
        }
      }
      &.-contact{
        > .foot-nav__nav__link{
          @include mq(sp) {
            width: 100%;
            height: auto;
            padding: 20px 0;
          }
        }
        a{
          @include mq(sp) {
            width: calc(100% - 50px);
            height: 60px;
            margin: 0 auto;
            border: 1px solid #fff;
            font-size: 1.6rem;
            font-weight: 500;
            letter-spacing: .06em;
            position: relative;
            z-index: 0;
            overflow: hidden;
            &:before{
              content: '';
              display: block;
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 100%;
              transform-origin: right bottom;
              z-index: -1;
              background-color: #fff;
              transform: translate(100%,0)skew(-45deg);
              right: 30px;
              transition: .4s ease;
            }
          }
        }
      }
    }
    &__link{
      display: block;
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: .06em;
      margin: 0 28px;
      @include mq(sp) {
        width: 50%;
        height: 60px;
        margin: 0;
        &:nth-child(2n){
          border-left: 1px solid #1e3246;
        }
      }
      a{
        @include mq(sp) {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    &__sub{
      a{
        display: block;
        font-size: 1.2rem;
        color: #8d9aa5;
        font-weight: 400;
        letter-spacing: .06em;
      }
    }
  }
  &__info{
    padding: 40px 0;
  }
  &__logo{
    width: 103px;
    margin: 0 auto;
    @include mq(sp) {
      width: 82px;
    }
  }
  &__access{
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: .06em;
    text-align: center;
    margin: 1.5em 0 0;
    @include mq(sp) {
      font-size: 1.2rem;
      line-height: 1.66;
    }
  }
  &__foot{
    background-color: #6e7882;
    // border-bottom: 15px solid #eef4f8;
    padding: 20px 0;
    @include mq(sp) {
      border-bottom: 0;
      padding: 20px 0 40px;
    }
    &__inner{
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include mq(sp) {
        width: 100%;
        display: block;
      }
    }
    &__left{
      display: flex;
      align-items: center;
      @include mq(sp) {
        display: block;
      }
      ul{
        display: flex;
        margin: 0 0 0 32px;
        @include mq(sp) {
          margin: 0 0 12px;
          justify-content: center;
        }
        li{
          line-height: 1.4;
          &:nth-child(n+2){
            border-left: 1px solid #fff;
            padding-left: 1em;
            margin-left: 1em;
          }
        }
        a{
          font-size: 1.2rem;
          display: block;
          color: #fff;
          letter-spacing: .06em;
          font-weight: 400;
        }
      }
    }
  }
  &__facebook{
    width: 40px;
    @include mq(sp) {
      margin: 0 auto 16px;
    }
    a{
      display: block;
    }
  }
  .copyright{
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: .06em;
    color: #fff;
    @include mq(sp) {
      text-align: center;
    }
  }
}