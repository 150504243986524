/* ========
text
======== */

.copy-xl{
  font-family: $serif;
  font-size: 2.9rem;
  font-weight: 600;
  letter-spacing: .1em;
  line-height: 1.5;
  text-align: center;
  margin: 0 0 16px;
  @include mq(sp) {
    font-size: 2.1rem;
  }
}

.copy-m{
  font-family: $serif;
  font-size: 2.4rem;
  font-weight: 600;
  letter-spacing: .1em;
  line-height: 1.5;
  text-align: center;
  margin: 0 0 16px;
  @include mq(sp) {
    font-size: 1.8rem;
  }
}

.copy-s{
  font-family: $serif;
  font-size: 1.9rem;
  font-weight: 600;
  letter-spacing: .1em;
  line-height: 1.66;
  text-align: center;
  @include mq(sp) {
    font-size: 1.6rem;
  }
}

.desc-l{
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: .06em;
  line-height: 2.1;
  margin: 1em 0;
  @include mq(sp) {
    font-size: 1.3rem;
  }
}

.desc-m{
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: .06em;
  line-height: 1.7;
  margin: 1em 0;
  @include mq(sp) {
    font-size: 1.3rem;
  }
}

.desc-s{
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: .06em;
  line-height: 2;
  margin: 1em 0;
  @include mq(sp) {
    font-size: 1.2rem;
  }
}

.desc-xs{
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: .06em;
  line-height: 1.8;
  margin: 1em 0;
  @include mq(sp) {
    font-size: 1.1rem;
  }
}

.text-error{
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: .06em;
  color: palettes(main,light);
  line-height: 1.4;
  margin: 16px 0 0;
}