/* ========
box-service-link
======== */

.box-service-link{
  display: flex;
  background-color: #fff;
  margin-top: 40px;
  @include mq(sp) {
    display: block;
  }
  &:first-child{
    margin-top: 0;
  }
  &.-reverse{
    @include mq(pc) {
      flex-direction: row-reverse;
    }
  }
  &__ph{
    width: 100%;
    flex: 1 1 auto;
    background: transparent center center no-repeat;
    background-size: cover;
    @include mq(sp) {
      width: calc(100% - 10px);
      height: 62vw;
    }
  }
  &__textarea{
    width: 475px;
    flex: 0 0 auto;
    padding: 84px 5px 5px;
    @include mq(sp) {
      width: 100%;
      padding: 16px 5px 5px;
    }
    .btn-basic{
      width: 100%;
      margin-top: 72px;
      @include mq(sp) {
        margin-top: 20px;
        height: 50px;
      }
    }
  }
  &__ttl{
    font-family: $serif;
    font-size: 2.4rem;
    font-weight: 600;
    letter-spacing: .1em;
    text-align: center;
    line-height: 1.8;
    margin: 0 0 32px;
    @include mq(sp) {
      font-size: 2.1rem;
      margin: 0 0 12px;
    }
    span{
      display: block;
      font-family: $century;
      font-size: 1.6rem;
      color: palettes(main);
      font-weight: 500;
      margin: 4px 0 0;
      @include mq(sp) {
        margin: 0;
      }
    }
  }
  &__copy{
    font-family: $serif;
    font-size: 1.8rem;
    letter-spacing: .1em;
    line-height: 1.66;
    font-weight: 500;
    text-align: center;
    margin: 0 0 16px;
    @include mq(sp) {
      font-size: 1.7rem;
    }
  }
  &__desc{
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: .06em;
    text-align: center;
  }
}