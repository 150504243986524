/* ========
unit-partner
======== */

.unit-partner{
  background-color: #fff;
  padding: 48px 0 32px;
  margin: 20px 0 0;
  @include mq(sp) {
    padding: 32px 0 24px;
  }
  &__ttl{
    font-family: $serif;
    font-size: 3.5rem;
    letter-spacing: .1em;
    font-weight: 600;
    text-align: center;
    line-height: 1.4;
    margin: 0 0 20px;
    @include mq(sp) {
      font-size: 2.6rem;
    }
  }
  &__list{
    width: 926px;
    margin: 0 auto;
    @include mq(sp) {
      width: 100%;
      padding: 0 $sp-padding/2;
    }
    ul{
      display: flex;
      flex-wrap: wrap;
    }
    li{
      margin: 0 5px 10px;
      width: calc(25% - 10px);
      height: 128px;
      background-color: #f6f6f6;
      // padding: 16px 0;
      position: relative;
      @include mq(sp) {
        width: calc(50% - 10px);
        margin: 0 $sp-padding/2 $sp-padding;
      }
      span{
        display: block;
        position: absolute;
        background: transparent center center no-repeat;
        background-size: contain;
        top: 8%;
        left: 12%;
        right: 12%;
        bottom: 8%;
      }
    }
  }
}