/* ========
intro-simple
======== */

.intro-simple{
  padding: 80px 0;
  @include mq(sp) {
    padding: 40px $sp-padding;
  }
  .desc-l{
    text-align: center;
  }
}