/* ========
lead-aboutus
======== */

.lead-aboutus{
  padding: 96px 0;
  background: transparent url(../images/common/bg_logo.png) right 0 bottom -120px no-repeat;
  background-size: 576px;
  position: relative;
  z-index: 0;
  @include mq(sp) {
    padding: 48px 0;
    background-size: 278px;
    background-position: right -20px bottom -40px;
  }
  .btn-basic{
    margin-top: 40px;
    @include mq(sp) {
      margin-top: 32px;
      width: calc(100% - 20px);
    }
  }
}