/* ========
table-form
======== */

.table-form{
  padding: 40px 0 80px;
  @include mq(sp){
    padding: 20px 0 40px;
  }
  table{
    background-color: #fff;
    width: 100%;
    @include mq(sp){
      background-color: transparent;
    }
    tr{
      border-top: 1px solid #ececec;
      @include mq(sp){
        display: block;
        border: none;
        background-color: #fff;
        margin-top: 10px;
      }
      &:nth-child(1){
        border-top: none;
        @include mq(sp){
          margin-top: 0;
        }
      }
      &.-empty{
        background-color: palettes(main,lightest);
        th{
          background-color: rgba(#fff,.2);
        }
        td{
          background-color: transparent;
        }
      }
    }
    th,td{
      font-size: 1.6rem;
      font-weight: 400;
      letter-spacing: .04em;
      text-align: left;
      line-height: 1.8;
      @include mq(sp){
        display: block;
      }
    }
    th{
      width: 300px;
      padding: 24px 32px;
      vertical-align: top;
      color: #555;
      font-weight: 700;
      @include mq(sp){
        width: 100%;
        padding: 8px 16px;
      }
      &.-required{
        &:after{
          content: '※必須';
          font-weight: 400;
          font-size: 1.3rem;
          color: palettes(main,light);
          margin: 0 0 0 1em;
        }
      }
    }
    td{
      padding: 24px;
      vertical-align: middle;
      background-color: #f6f6f6;
      @include mq(sp){
        padding: 16px;
      }
    }
    input, textarea{
      width: 100%;
      padding: 4px 8px;
      background-color: #fff;
      border: 1px solid #d0d0d0;
      font-size: 1.6rem;
    }
    textarea{
      min-height: 240px;
      max-height: 480px;
      resize: vertical;
      @include mq(sp){
        min-height: 120px;
        max-height: 360px;
      }
    }
    select{
      width: 400px;
      padding: 4px 8px;
      border: 1px solid #d0d0d0;
      font-size: 1.6rem;
      background: #fff;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' overflow='scroll' viewBox='0 0 13.54999 12'%3E%3Cdefs/%3E%3Cpath d='M7.64102 11.5l5.77351-10.00001C13.79942.83333 13.3183 0 12.5485 0H1.00149C.23169 0-.24943.83333.13547 1.49999L5.90897 11.5c.3849.66666 1.34715.66666 1.73205 0z'/%3E%3C/svg%3E");
      background-size: .5em;
      background-repeat: no-repeat;
      background-position: right 12px center;
      @include mq(sp) {
        width: 100%;
      }
    }
  }
  &__privacy{
    background-color: #fff;
    margin: 40px 0;
    width: 100%;
    height: 240px;
    overflow-y: scroll;
    padding: 32px;
    font-size: 1.4rem;
    letter-spacing: .04em;
    line-height: 1.66;
    border: 1px solid #d0d0d0;
    @include mq(sp){
      padding: 24px;
    }
    p{
      & + p{
        margin-top: .75em;
      }
    }
  }
  &__foot{
    p{
      text-align: center;
      font-size: 1.4rem;
      @include mq(sp){
        text-align: left;
      }
    }
  }
  &__confirm{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    margin: 20px 0 40px;
    @include mq(sp){
      padding: 0 10px;
    }
    label{
      display: flex;
      align-items: center;
    }
    input{
      width: 20px;
      height: 20px;
      flex: 0 0 auto;
      background-color: #fff;
      box-shadow: 0 0 0 1px #d0d0d0;
      margin: 0 .5em 0 0;
      &:checked{
        border: 4px solid #fff;
        background-color: #888;
      }
    }
  }
  &__submit{
    input{
      width: 320px;
      height: 60px;
      background-color: palettes(main);
      color: #fff;
      cursor: pointer;
      letter-spacing: .2em;
      text-align: center;
      margin: 0 auto;
      font-size: 1.8rem;
      @include mq(pc) {
        &:hover{
          opacity: .6;
        }
      }
    }
    // .btn-basic{
    //   margin: 0 auto;
    //   button{
    //     .-ja{
    //       font-family: $serif;
    //       font-size: 2rem;
    //       letter-spacing: .2em;
    //     }
    //   }
    // }
  }
}