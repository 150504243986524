/* ========
slider-note
======== */

.slider-note{
  width: 345px;
  margin: 0 auto;
  position: relative;
  z-index: 0;
  @include mq(sp) {
    width: 275px;
  }
  .item-note{
    margin: 0 5px;
    &__desc{
      @include mq(pc) {
        min-height: 5.1em;
      }
    }
  }
  .slick-list{
    overflow: visible;
  }
  &__arrow{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: palettes(main);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    cursor: pointer;
    z-index: 3;
    @include mq(pc) {
      transition: .4s ease;
      &:hover{
        background-color: darken(palettes(main),4%);
      }
    }
    @include mq(sp) {
      width: 45px;
      height: 45px;
    }
    &:before{
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7.5px 20px 7.5px 0;
      border-color: transparent #fff transparent transparent;
      position: absolute;
      top: 0;
      left: 0;
      right: 10%;
      bottom: 0;
      margin: auto;
      @include mq(sp) {
        border-width: 6px 15px 6px 0;
      }
    }
    &.-prev{
      left: 0;
      transform: translate(-50%,0);
    }
    &.-next{
      right: 0;
      transform: translate(50%,0)rotate(180deg);
    }
  }
}