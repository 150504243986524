/* ========
mainvisual
======== */

.mainvisual{
  opacity: 0;
  width: 100%;
  height: 100vh;
  min-height: 600px;
  background: transparent url(../images/top/mainvisual.jpg) center center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  @include mq(sp) {
    display: block;
    height: auto;
    background: none;
    &:before{
      content: '';
      display: block;
      width: 100%;
      height: 80vw;
      background: transparent url(../images/top/mainvisual.jpg) center center no-repeat;
      background-size: cover;
    }
  }
  &__textarea{
    text-align: right;
    padding: 80px;
    @include mq(sp) {
      text-align: left;
      padding: 10px 24px 40px;
      background-color: #00192d;
    }
  }
  &__ttl{
    opacity: 0;
    font-size: 10.8rem;
    display: block;
    margin: 0 0 24px;
    @include mq(sp) {
      font-size: 7.6rem;
      margin: 0 0 4px;
    }
    img{
      width: 3.5em;
      margin-left: auto;
      @include mq(sp) {
        margin-left: 0;
        margin-top: -1em;
      }
    }
  }
  &__copy{
    opacity: 0;
    font-family: $serif;
    color: #fff;
    font-size: 3.7rem;
    font-weight: 600;
    line-height: 1.66;
    letter-spacing: .1em;
    margin: 0 0 32px;
    @include mq(sp) {
      font-size: 2.8rem;
      margin: 0 0 16px;
    }
    span{
      display: block;
    }
  }
  &__desc{
    opacity: 0;
    font-family: $century;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: .1em;
    line-height: 2;
    color: #fff;
    @include mq(sp) {
      font-size: 1.3rem;
    }
    span{
      display: block;
    }
  }


  &.-animated{
    opacity: 1;
    transition: opacity 1.2s ease;
    .mainvisual__ttl{
      opacity: 1;
      transition: 1.6s ease .5s;
    }
    .mainvisual__copy{
      opacity: 1;
      transition: 1.6s ease .8s;
    }
    .mainvisual__desc{
      opacity: 1;
      transition: 1.6s ease 1.1s;
    }
  }
}