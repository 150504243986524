/* ========
unit-corporate
======== */

.unit-corporate{
  padding: 80px 0 100px;
  .ttl-type02{
    margin-bottom: 32px;
  }
  &__table{
    width: 100%;
    @include mq(sp) {
      width: calc(100% - 40px);
      margin: 0 auto;
    }
    tbody{
      width: 100%;
    }
    tr{
      border-top: 1px solid #d2d7dc;
      border-bottom: 1px solid #d2d7dc;
    }
    th,td{
      letter-spacing: .06em;
      vertical-align: top;
      text-align: justify;
      line-height: 1.8;
      padding: 20px 0;
      @include mq(sp) {
        padding: 12px 0;
      }
      a{
        color: #0064b4;
        @include mq(pc) {
          &:hover{
            opacity: 1;
            text-decoration: underline;
          }
        }
      }
    }
    th{
      font-size: 1.6rem;
      font-weight: 500;
      padding-right: 40px;
      text-align: left;
      @include mq(sp) {
        font-size: 1.5rem;
        width: 120px;
        padding-right: 16px;
      }
    }
    td{
      font-size: 1.4rem;
      font-weight: 400;
      @include mq(sp) {
        font-size: 1.3rem;
        width: calc(100% - 120px);
      }
    }
  }
}