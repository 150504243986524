/* ========
lead-info
======== */

.lead-info{
  padding: 80px 0;
  @include mq(sp) {
    padding: 40px 10px 50px;
  }
  &__list{
    li{
      border-bottom: 1px solid #d2d7dc;
    }
  }
  .btn-basic{
    margin: 30px auto 0;
    @include mq(sp) {
      margin: 20px auto 0;
      width: calc(100% - 50px);
    }
  }
}