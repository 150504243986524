/* ========
item-note
======== */

.item-note{
  width: 335px;
  background-color: #00192d;
  padding: 0 0 10px;
  position: relative;
  z-index: 0;
  @include mq(sp) {
    width: 265px;
  }
  .btn-basic{
    width: calc(100% - 20px);
    height: 50px;
    margin: 0 auto;
    @include mq(sp) {
      height: 40px;
    }
    a{
      font-size: 1.8rem;
      @include mq(pc) {
        &:hover{
          &:before{
            right: 40px;
          }
        }
      }
      @include mq(sp) {
        font-size: 1.6rem;
      }
      &:before{
        right: 20px;
      }
    }
  }
  &__tag{
    position: absolute;
    top: 15px;
    right: 0;
    background-color: palettes(main);
    color: #fff;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: .06em;
    line-height: 1;
    width: 120px;
    padding: 8px 0 8px 8px;
    text-align: center;
    border-radius: 60px 0 0 60px;
    @include mq(sp) {
      font-size: 1.2rem;
    }
  }
  &__img{
    padding: 0 20px 0 0;
  }
  &__textarea{
    padding: 16px 20px 20px;
    @include mq(sp) {
      padding: 20px 24px;
    }
  }
  &__date{
    font-size: 1.3rem;
    letter-spacing: .06em;
    font-weight: 400;
    color: #fff;
    @include mq(sp) {
      font-size: 1.2rem;
    }
  }
  &__ttl{
    font-size: 2.1rem;
    letter-spacing: .06em;
    font-weight: 500;
    color: #fff;
    line-height: 1.56;
    margin: 8px 0;
    @include mq(sp) {
      font-size: 1.8rem;
    }
  }
  &__desc{
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: .06em;
    color: #fff;
    text-align: justify;
    line-height: 1.7;
    @include mq(sp) {
      font-size: 1.2rem;
    }
  }
}