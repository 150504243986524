/* ========
nav-pager00000
======== */

.nav-pager{
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .page-numbers{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    &:not([class*="prev"]){
      &:not([class*="next"]){
        width: 50px;
        height: 50px;
        border: 1px solid #6e7882;
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: .06em;
        color: #6e7882;
        &.current{
          color: palettes(main);
          border: 2px solid palettes(main);
        }
      }
    }
    &.prev, &.next{
      margin: 0 25px;
    }
  }
  >span{
    margin: 0 25px;
    opacity: .3;
  }
  ul{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__arrow{
    width: 120px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6e7882;
    color: #fff;
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: .06em;
  }
}