/* ========
unit-service-detail
======== */

.unit-service-detail{
  padding: 40px;
  counter-reset: serviceDetail;
  position: relative;
  z-index: 0;
  @include mq(sp) {
    padding: 24px $sp-padding;
  }
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    min-width: $pc-min-width;
    background-color: #00192d;
    z-index: -1;
  }
  .box-service-detail{
    &:nth-child(n+2){
      margin-top: 30px;
    }
  }
}