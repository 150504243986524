/*--==========================
base
============================--*/
@import "base/reset";
@import "base/vars";
@import "base/mixins";
@import "plugins/_00_plugins.scss";
@import "base/default";
@import "base/editor";
@import "base/animation";

/*--==========================
layout
============================--*/
@import "layout/_01_layout.scss";
@import "layout/_02_l-content.scss";
@import "layout/_02_l-footer.scss";
@import "layout/_02_l-header.scss";
@import "layout/_02_l-main.scss";
@import "layout/_02_l-side.scss";

/*--==========================
  module
============================--*/
@import "module/_00_button.scss";
@import "module/_00_prlxView.scss";
@import "module/_00_text.scss";
@import "module/_00_title.scss";
@import "module/_01_foot-nav.scss";
@import "module/_01_gnav.scss";
@import "module/_02_area-hero.scss";
@import "module/_02_breadcrumbs.scss";
@import "module/_02_mainvisual.scss";
@import "module/_02_section.scss";
@import "module/_03_lead-aboutus.scss";
@import "module/_03_lead-case-study.scss";
@import "module/_03_lead-facebook.scss";
@import "module/_03_lead-info.scss";
@import "module/_03_lead-note.scss";
@import "module/_03_lead-service.scss";
@import "module/_04_box-contact-link.scss";
@import "module/_04_box-service-detail.scss";
@import "module/_04_box-service-link.scss";
@import "module/_05_list-info.scss";
@import "module/_05_list-text.scss";
@import "module/_06_tab-link.scss";
@import "module/_07_item-info.scss";
@import "module/_07_item-note.scss";
@import "module/_08_unit-access.scss";
@import "module/_08_unit-col2box.scss";
@import "module/_08_unit-contact.scss";
@import "module/_08_unit-corporate.scss";
@import "module/_08_unit-greeting.scss";
@import "module/_08_unit-icon-content.scss";
@import "module/_08_unit-mission.scss";
@import "module/_08_unit-modal.scss";
@import "module/_08_unit-movie.scss";
@import "module/_08_unit-partner.scss";
@import "module/_08_unit-pickup.scss";
@import "module/_08_unit-school-link.scss";
@import "module/_08_unit-service-detail.scss";
@import "module/_08_unit-text-base.scss";
@import "module/_09_slider-movie.scss";
@import "module/_09_slider-note.scss";
@import "module/_10_intro-area.scss";
@import "module/_10_intro-simple.scss";
@import "module/_11_nav-pager.scss";
@import "module/_12_detail-info.scss";
@import "module/_13_table-form.scss";
@import "module/_99_modules.scss";
@import "module/_99_utility.scss";

/*--==========================
  page
============================--*/
@import "page/_00_top.scss";
@import "page/_01_page-thanks.scss";

//先方追記
@import "base/addition";
