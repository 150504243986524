@charset "UTF-8";
/*--==========================
base
============================--*/
/*--------------------------------------------------------------------------
   reset
---------------------------------------------------------------------------*/
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong,
sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul, li {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

img {
  border: 0;
  vertical-align: top; }

em {
  font-style: italic; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0; }

input, select, textarea {
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: middle; }

input:focus, textarea:focus, select:focus {
  outline: none; }

h1, h2, h3, h4, h5 {
  font-weight: inherit; }

/*------------------------------
表示・非表示切替
-------------------------------*/
@media (max-width: 960px) {
  .pc {
    display: none !important; } }

@media (min-width: 961px) {
  .sp {
    display: none !important; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* ========
html
======== */
html {
  font-size: 62.5%; }

/* ========
body
======== */
body {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  letter-spacing: 0;
  font-family: "Noto Sans JP", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
  color: #000;
  line-height: 2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  min-width: 1200px; }
  @media (max-width: 960px) {
    body {
      min-width: 320px; } }

/* ========
tags
======== */
* {
  box-sizing: border-box; }
  *::selection {
    color: #21252b;
    background: #e1e4e8; }

a {
  color: inherit;
  outline: none;
  opacity: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  @media (min-width: 961px) {
    a {
      transition: .4s ease; }
      a:hover {
        opacity: .6; } }

a:link,
a:visited {
  text-decoration: none; }

a:hover {
  text-decoration: none;
  outline: none; }

a:active {
  outline: none; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

/* ========
input
======== */
input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none; }

select::-ms-expand {
  display: none; }

input, button, textarea, select {
  background-color: gray;
  display: block;
  padding: 5px; }

/*-------------------------------
WordPressエディタのデフォルト設定
--------------------------------*/
.wp-editor-content * {
  max-width: 100%; }

.wp-editor-content strong {
  font-weight: bold; }

.wp-editor-content em {
  font-style: italic; }

.wp-editor-content blockquote {
  padding: 15px;
  border: 1px solid #CCCCCC;
  color: #212121;
  box-shadow: 1px 1px 2px rgba(204, 204, 204, 0.9), inset 0 1px 0 rgba(255, 255, 255, 0.4);
  clear: both; }

.wp-editor-content .aligncenter {
  display: block;
  margin: 0 auto;
  text-align: center; }

.wp-editor-content .alignright {
  float: right; }

.wp-editor-content .alignleft {
  float: left; }

.wp-editor-content img[class*="wp-image-"],
.wp-editor-content img[class*="attachment-"] {
  height: auto;
  max-width: 100%; }

.wp-editor-content .clearfix {
  overflow: hidden;
  zoom: 1; }

.wp-editor-content .clearfix:after {
  content: "";
  display: block;
  clear: both; }

.wp-editor-content h1, .wp-editor-content h2, .wp-editor-content h3, .wp-editor-content h4, .wp-editor-content h5, .wp-editor-content h6, .wp-editor-content img, .wp-editor-content p, .wp-editor-content ul, .wp-editor-content ol, .wp-editor-content blockquote, .wp-editor-content table {
  margin-bottom: 14px; }

.wp-editor-content h1, .wp-editor-content h2, .wp-editor-content h3, .wp-editor-content h4, .wp-editor-content h5, .wp-editor-content h6 {
  margin-top: 28px; }
  .wp-editor-content h1:first-child, .wp-editor-content h2:first-child, .wp-editor-content h3:first-child, .wp-editor-content h4:first-child, .wp-editor-content h5:first-child, .wp-editor-content h6:first-child {
    margin-top: 0; }

.wp-editor-content h1 {
  border-bottom: 4px solid #000;
  color: #000;
  padding: 10px; }

.wp-editor-content a {
  color: #000; }

.wp-editor-content ul, .wp-editor-content ol {
  padding-left: 1.2em; }

.wp-editor-content ul li {
  list-style-type: disc; }

.wp-editor-content ol li {
  list-style-type: decimal; }

.wp-editor-content table {
  border: 1px solid #CCCCCC; }
  .wp-editor-content table th, .wp-editor-content table td {
    border: 1px solid #CCCCCC;
    padding: .5em 1em; }

/* ========
animation
======== */
/*--==========================
layout
============================--*/
/* ========
.layout
======== */
.l-inside {
  max-width: 990px;
  margin: 0 auto; }
  @media (max-width: 960px) {
    .l-inside {
      max-width: 100%; } }

.l-inner {
  min-width: 990px;
  padding: 0 40px;
  margin: 0 auto; }
  @media (max-width: 960px) {
    .l-inner {
      min-width: 0;
      padding: 0 10px; } }

.l-wrapper {
  position: relative;
  overflow: hidden; }
  @media (max-width: 960px) {
    .l-wrapper {
      padding: 60px 0 0; } }

@media (min-width: 961px) {
  .l-main {
    margin-left: 210px; } }

/* ========
l-content
======== */
/* ========
l-footer
======== */
/* ========
l-header
======== */
.l-header {
  position: fixed;
  padding: 40px 0 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 210px;
  z-index: 999; }
  @media (max-width: 960px) {
    .l-header {
      width: auto;
      padding: 0;
      bottom: auto;
      right: 0; } }

/* ========
l-main
======== */
/* ========
l-side
======== */
/*--==========================
  module
============================--*/
/* ========
button
======== */
.btn-basic {
  width: 465px;
  height: 60px; }
  @media (max-width: 960px) {
    .btn-basic {
      width: 100%; } }
  .btn-basic a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-family: "Abhaya Libre", serif;
    font-size: 2.1rem;
    color: #fff;
    font-weight: 400;
    letter-spacing: .1em;
    line-height: 1.2;
    position: relative;
    z-index: 0;
    overflow: hidden;
    background-color: #a60019; }
    @media (min-width: 961px) {
      .btn-basic a:hover {
        opacity: 1; }
        .btn-basic a:hover:before {
          right: 50px; } }
    .btn-basic a span {
      font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
      font-size: 1.3rem;
      font-weight: 400;
      letter-spacing: .06em;
      display: block; }
    .btn-basic a:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      transform-origin: right bottom;
      z-index: -1;
      background-color: #6e0000;
      transform: translate(100%, 0) skew(-45deg);
      right: 30px;
      transition: .4s ease; }
    .btn-basic a[target="_blank"] {
      font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
      display: flex;
      flex-direction: row;
      font-size: 1.5rem; }
      .btn-basic a[target="_blank"]:after {
        content: '';
        display: block;
        width: 1em;
        height: 1em;
        background: transparent center center no-repeat;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512.00098 512'%3E%3Cdefs/%3E%3Cstyle%3E.st0{fill:%23fff}%3C/style%3E%3Cpath d='M488.72699 0H302.54501c-12.853 0-23.27301 10.42-23.27301 23.273s10.42001 23.273 23.27301 23.273h129.99698l-239.543 239.544c-9.089 9.08899-9.089 23.823 0 32.91199 4.543 4.54401 10.49899 6.81601 16.455 6.81601 5.95599 0 11.91299-2.271 16.457-6.81699L465.45499 79.458v129.99698c0 12.853 10.42001 23.27299 23.27301 23.27299s23.27298-10.42 23.27298-23.27299V23.273C512 10.42 501.57999 0 488.72699 0z' class='st0'/%3E%3Cpath d='M395.63599 232.72701c-12.853 0-23.27301 10.42-23.27301 23.27299v209.45502H46.545V139.636H256c12.853 0 23.27301-10.42 23.27301-23.273S268.853 93.091 256 93.091H23.273C10.42 93.091 0 103.511 0 116.364v372.36401C0 501.57999 10.42 512 23.273 512h372.36401c12.853 0 23.27301-10.42001 23.27301-23.27301V256c-.00102-12.853-10.42101-23.27299-23.27403-23.27299z' class='st0'/%3E%3C/svg%3E");
        background-size: 80%;
        margin: 0 0 0 .25em; }
  .btn-basic.-ja a {
    font-family: "Noto Sans JP", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
    font-size: 1.6rem; }
  .btn-basic.-row2 {
    height: 80px; }
  .btn-basic.-ghost a {
    background-color: transparent;
    border: 1px solid #fff; }
    .btn-basic.-ghost a:before {
      background-color: #fff; }
  .btn-basic.-ghost.-black a {
    color: #000;
    border-color: #000; }
    .btn-basic.-ghost.-black a:before {
      background-color: #000; }

.btn-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 60px;
  background-color: #780000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .btn-menu span {
    display: block;
    width: 30px;
    height: 2px;
    background-color: #fff;
    position: relative; }
    .btn-menu span:before, .btn-menu span:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      background-color: #fff; }
    .btn-menu span:before {
      top: -8px; }
    .btn-menu span:after {
      bottom: -8px; }

/*--------------------------------
prlx-view
-----------------------------------*/
.prlx-view[data-prlx="fade"] {
  opacity: 0; }

.prlx-view[data-prlx="fade-to-up"] {
  transform: translate(0, 40px);
  opacity: 0; }

.prlx-view[data-prlx="fade-to-left"] {
  transform: translate(60px, 0);
  opacity: 0; }

.prlx-view[data-prlx="fade-to-right"] {
  transform: translate(-60px, 0);
  opacity: 0; }

.prlx-view.-animated[data-prlx="fade"] {
  opacity: 1;
  transition: 0.7s cubic-bezier(0.43, 0.88, 0.65, 1); }

.prlx-view.-animated[data-prlx="fade-to-up"] {
  transition: 0.7s cubic-bezier(0.43, 0.88, 0.65, 1);
  transform: translate(0, 0);
  opacity: 1; }

.prlx-view.-animated[data-prlx="fade-to-left"] {
  transition: 0.7s cubic-bezier(0.43, 0.88, 0.65, 1);
  transform: translate(0, 0);
  opacity: 1; }

.prlx-view.-animated[data-prlx="fade-to-right"] {
  transition: 0.7s cubic-bezier(0.43, 0.88, 0.65, 1);
  transform: translate(0, 0);
  opacity: 1; }

/* ========
text
======== */
.copy-xl {
  font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 2.9rem;
  font-weight: 600;
  letter-spacing: .1em;
  line-height: 1.5;
  text-align: center;
  margin: 0 0 16px; }
  @media (max-width: 960px) {
    .copy-xl {
      font-size: 2.1rem; } }

.copy-m {
  font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 2.4rem;
  font-weight: 600;
  letter-spacing: .1em;
  line-height: 1.5;
  text-align: center;
  margin: 0 0 16px; }
  @media (max-width: 960px) {
    .copy-m {
      font-size: 1.8rem; } }

.copy-s {
  font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 1.9rem;
  font-weight: 600;
  letter-spacing: .1em;
  line-height: 1.66;
  text-align: center; }
  @media (max-width: 960px) {
    .copy-s {
      font-size: 1.6rem; } }

.desc-l {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: .06em;
  line-height: 2.1;
  margin: 1em 0; }
  @media (max-width: 960px) {
    .desc-l {
      font-size: 1.3rem; } }

.desc-m {
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: .06em;
  line-height: 1.7;
  margin: 1em 0; }
  @media (max-width: 960px) {
    .desc-m {
      font-size: 1.3rem; } }

.desc-s {
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: .06em;
  line-height: 2;
  margin: 1em 0; }
  @media (max-width: 960px) {
    .desc-s {
      font-size: 1.2rem; } }

.desc-xs {
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: .06em;
  line-height: 1.8;
  margin: 1em 0; }
  @media (max-width: 960px) {
    .desc-xs {
      font-size: 1.1rem; } }

.text-error {
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: .06em;
  color: #d90021;
  line-height: 1.4;
  margin: 16px 0 0; }

/* ========
title
======== */
.ttl-type01 {
  font-family: "Abhaya Libre", serif;
  font-size: 5rem;
  font-weight: 500;
  letter-spacing: .08em;
  line-height: 1.4;
  text-align: center;
  color: #00192d;
  margin: 0 0 12px; }
  @media (max-width: 960px) {
    .ttl-type01 {
      font-size: 4.3rem; } }
  .ttl-type01:after {
    content: '';
    display: block;
    width: 3px;
    height: 25px;
    background-color: #a60019;
    margin: 0 auto; }
    @media (max-width: 960px) {
      .ttl-type01:after {
        height: 20px; } }

.ttl-type02 {
  text-align: center;
  line-height: 1.2;
  margin: 0 0 24px; }
  .ttl-type02__en {
    font-family: "Abhaya Libre", serif;
    font-size: 5rem;
    font-weight: 500;
    letter-spacing: .08em;
    color: #00192d; }
    @media (max-width: 960px) {
      .ttl-type02__en {
        font-size: 3.6rem; } }
  .ttl-type02__ja {
    font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .1em; }

.ttl-type03 {
  font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 2.9rem;
  font-weight: 600;
  letter-spacing: .1em;
  line-height: 1.4;
  text-align: center;
  color: #00192d;
  margin: 0 0 12px; }
  @media (max-width: 960px) {
    .ttl-type03 {
      font-size: 2rem; } }
  .ttl-type03:after {
    content: '';
    display: block;
    width: 3px;
    height: 20px;
    background-color: #a60019;
    margin: 12px auto 0; }
    @media (max-width: 960px) {
      .ttl-type03:after {
        height: 15px; } }

/* ========
foot-nav
======== */
.foot-nav__nav {
  background-color: #00192d;
  color: #fff;
  padding: 56px 0;
  display: flex;
  justify-content: center; }
  @media (max-width: 960px) {
    .foot-nav__nav {
      flex-wrap: wrap;
      padding: 0; } }
  .foot-nav__nav__grp {
    display: flex;
    justify-content: center; }
    @media (max-width: 960px) {
      .foot-nav__nav__grp {
        width: 100%;
        margin: 0;
        text-align: center;
        border-bottom: 1px solid #1e3246; } }
    .foot-nav__nav__grp.-service {
      display: block;
      margin: 0 28px; }
      @media (max-width: 960px) {
        .foot-nav__nav__grp.-service {
          padding: 12px 0 16px; } }
      .foot-nav__nav__grp.-service > .foot-nav__nav__link {
        margin: 0; }
        @media (max-width: 960px) {
          .foot-nav__nav__grp.-service > .foot-nav__nav__link {
            width: 100%;
            height: auto; } }
      @media (max-width: 960px) {
        .foot-nav__nav__grp.-service {
          width: 100%; } }
    @media (max-width: 960px) {
      .foot-nav__nav__grp.-contact > .foot-nav__nav__link {
        width: 100%;
        height: auto;
        padding: 20px 0; } }
    @media (max-width: 960px) {
      .foot-nav__nav__grp.-contact a {
        width: calc(100% - 50px);
        height: 60px;
        margin: 0 auto;
        border: 1px solid #fff;
        font-size: 1.6rem;
        font-weight: 500;
        letter-spacing: .06em;
        position: relative;
        z-index: 0;
        overflow: hidden; }
        .foot-nav__nav__grp.-contact a:before {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 100%;
          transform-origin: right bottom;
          z-index: -1;
          background-color: #fff;
          transform: translate(100%, 0) skew(-45deg);
          right: 30px;
          transition: .4s ease; } }
  .foot-nav__nav__link {
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .06em;
    margin: 0 28px; }
    @media (max-width: 960px) {
      .foot-nav__nav__link {
        width: 50%;
        height: 60px;
        margin: 0; }
        .foot-nav__nav__link:nth-child(2n) {
          border-left: 1px solid #1e3246; } }
    @media (max-width: 960px) {
      .foot-nav__nav__link a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center; } }
  .foot-nav__nav__sub a {
    display: block;
    font-size: 1.2rem;
    color: #8d9aa5;
    font-weight: 400;
    letter-spacing: .06em; }

.foot-nav__info {
  padding: 40px 0; }

.foot-nav__logo {
  width: 103px;
  margin: 0 auto; }
  @media (max-width: 960px) {
    .foot-nav__logo {
      width: 82px; } }

.foot-nav__access {
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: .06em;
  text-align: center;
  margin: 1.5em 0 0; }
  @media (max-width: 960px) {
    .foot-nav__access {
      font-size: 1.2rem;
      line-height: 1.66; } }

.foot-nav__foot {
  background-color: #6e7882;
  padding: 20px 0; }
  @media (max-width: 960px) {
    .foot-nav__foot {
      border-bottom: 0;
      padding: 20px 0 40px; } }
  .foot-nav__foot__inner {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 960px) {
      .foot-nav__foot__inner {
        width: 100%;
        display: block; } }
  .foot-nav__foot__left {
    display: flex;
    align-items: center; }
    @media (max-width: 960px) {
      .foot-nav__foot__left {
        display: block; } }
    .foot-nav__foot__left ul {
      display: flex;
      margin: 0 0 0 32px; }
      @media (max-width: 960px) {
        .foot-nav__foot__left ul {
          margin: 0 0 12px;
          justify-content: center; } }
      .foot-nav__foot__left ul li {
        line-height: 1.4; }
        .foot-nav__foot__left ul li:nth-child(n+2) {
          border-left: 1px solid #fff;
          padding-left: 1em;
          margin-left: 1em; }
      .foot-nav__foot__left ul a {
        font-size: 1.2rem;
        display: block;
        color: #fff;
        letter-spacing: .06em;
        font-weight: 400; }

.foot-nav__facebook {
  width: 40px; }
  @media (max-width: 960px) {
    .foot-nav__facebook {
      margin: 0 auto 16px; } }
  .foot-nav__facebook a {
    display: block; }

.foot-nav .copyright {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: .06em;
  color: #fff; }
  @media (max-width: 960px) {
    .foot-nav .copyright {
      text-align: center; } }

/* ========
gnav
======== */
.gnav {
  height: 100%;
  background-color: #a60019;
  padding: 0 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative; }
  @media (max-width: 960px) {
    .gnav {
      height: 60px; } }
  .gnav__logo {
    width: calc(100% - 10px);
    background-color: #fff;
    padding: 35px 38px;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (min-width: 961px) {
      .gnav__logo:hover {
        opacity: 1; }
        .gnav__logo:hover img {
          opacity: .6; } }
    @media (max-width: 960px) {
      .gnav__logo {
        width: 90px;
        height: 90px;
        padding: 0;
        transition: .4s ease; } }
    @media (min-width: 961px) {
      .gnav__logo img {
        width: 100%;
        transition: .4s ease; } }
    @media (max-width: 960px) {
      .gnav__logo img {
        width: 66%; } }
  .gnav__nav {
    margin: 10px 0 0; }
    @media (max-width: 960px) {
      .gnav__nav {
        display: none;
        padding: 60px 0 24px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 25, 45, 0.85);
        overflow-y: scroll;
        max-height: 100vh; }
        .gnav__nav:before {
          content: '';
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          border-top: 60px solid #a60019; } }
    .gnav__nav li:after {
      content: '';
      display: block;
      border-top: 1px solid #870000;
      border-bottom: 1px solid #b41428; }
      @media (max-width: 960px) {
        .gnav__nav li:after {
          border-top-color: rgba(255, 255, 255, 0.15);
          border-bottom: 0; } }
    .gnav__nav li > a {
      color: #fff;
      display: block;
      text-align: center;
      padding: 10px 0 16px;
      transition: .4s ease; }
      @media (min-width: 961px) {
        .gnav__nav li > a:hover {
          opacity: 1;
          background-color: #870000; } }
      .gnav__nav li > a span {
        display: block;
        line-height: 1.333; }
        .gnav__nav li > a span.-en {
          font-family: "Abhaya Libre", serif;
          font-size: 2.1rem;
          letter-spacing: .12em;
          font-weight: 400; }
        .gnav__nav li > a span.-ja {
          font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
          font-size: 1.1rem;
          letter-spacing: .12em;
          font-weight: 500; }
    @media (max-width: 960px) {
      .gnav__nav .btn-basic {
        width: calc(100% - 50px);
        margin: 24px auto 16px; }
        .gnav__nav .btn-basic a {
          font-family: "Noto Sans JP", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
          font-size: 1.6rem; } }
    .gnav__nav__facebook {
      width: 40px;
      margin: 0 auto; }
    .gnav__nav__sub {
      position: absolute;
      top: 0;
      left: 100%;
      right: 0;
      bottom: 0;
      width: 340px;
      background-color: rgba(25, 30, 35, 0.9);
      padding: 30px 10px;
      display: none; }
      @media (max-width: 960px) {
        .gnav__nav__sub {
          display: none !important; } }
      .gnav__nav__sub__ttl {
        line-height: 1;
        margin: 0 0 16px; }
        .gnav__nav__sub__ttl span {
          text-align: center;
          color: #fff;
          display: block; }
          .gnav__nav__sub__ttl span.-en {
            font-family: "Abhaya Libre", serif;
            font-size: 3.8rem;
            letter-spacing: .08em;
            font-weight: 500;
            line-height: 1; }
          .gnav__nav__sub__ttl span.-ja {
            font-size: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
            font-size: 1.4rem;
            font-weight: 500;
            letter-spacing: .12em; }
      .gnav__nav__sub__links a {
        display: block;
        padding: 0;
        margin: 0 0 10px;
        position: relative;
        z-index: 0;
        overflow: hidden; }
        .gnav__nav__sub__links a img {
          position: relative;
          z-index: 0;
          margin-top: -32px; }
      .gnav__nav__sub__links p {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 72px;
        background-color: #fff;
        color: #000;
        font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
        font-size: 1.8rem;
        line-height: 1;
        letter-spacing: .1em;
        text-align: center;
        font-weight: 600;
        margin-top: -32px;
        position: relative;
        z-index: 1; }
        .gnav__nav__sub__links p span {
          font-family: "Abhaya Libre", serif;
          display: block;
          line-height: 1;
          color: #a60019;
          font-size: 1.4rem;
          font-weight: 500;
          margin: 4px 0 0; }
  .gnav__contact {
    width: calc(100% - 20px);
    margin: 10px auto 0; }
    @media (max-width: 960px) {
      .gnav__contact {
        display: none; } }
    .gnav__contact a {
      display: block;
      background-color: #6e0000;
      color: #fff;
      text-align: center;
      padding: 16px 0; }
      @media (min-width: 961px) {
        .gnav__contact a:hover {
          opacity: 1;
          background-color: #870000; } }
      .gnav__contact a span {
        display: block;
        line-height: 1.2; }
        .gnav__contact a span.-en {
          font-family: "Abhaya Libre", serif;
          font-size: 1.8rem;
          letter-spacing: .1em;
          margin: 8px 0 0; }
        .gnav__contact a span.-ja {
          font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
          font-size: 1rem;
          letter-spacing: .12em; }
    .gnav__contact img {
      width: 33px;
      margin: 0 auto; }
  .gnav__facebook {
    display: flex;
    justify-content: center;
    margin: 16px 0; }
    @media (max-width: 960px) {
      .gnav__facebook {
        display: none; } }
    .gnav__facebook a {
      display: block;
      padding: 4px 12px; }
      .gnav__facebook a img {
        width: 14px; }
  .gnav .copyright {
    font-size: 1rem;
    color: #fff;
    text-align: center;
    font-weight: 400;
    letter-spacing: .06em;
    line-height: 1.7; }
    @media (max-width: 960px) {
      .gnav .copyright {
        display: none; } }
  .gnav.-slim .gnav__logo {
    padding: 18px 38px; }
    .gnav.-slim .gnav__logo img {
      width: 88%; }
  .gnav.-slim .gnav__nav li > a {
    padding: 4px 0 10px; }
    .gnav.-slim .gnav__nav li > a span {
      line-height: 1.24; }
  .gnav.-slim .gnav__contact a {
    padding: 10px 0 8px; }
    .gnav.-slim .gnav__contact a img {
      width: 28px; }
    .gnav.-slim .gnav__contact a span.-ja {
      display: none; }
    .gnav.-slim .gnav__contact a span.-en {
      font-size: 1.6rem; }

/* ========
area-hero
======== */
.area-hero {
  height: 440px;
  background: #5a646d center center no-repeat;
  background-size: cover; }
  @media (max-width: 960px) {
    .area-hero {
      height: 220px; } }
  .area-hero .l-main {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0; }
    @media (max-width: 960px) {
      .area-hero .l-main {
        padding: 0 0 16px; } }
  .area-hero__ttl {
    color: #fff;
    line-height: 1.2;
    text-align: center; }
    .area-hero__ttl__ja {
      font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
      letter-spacing: .1em;
      font-size: 5.4rem;
      font-weight: 600; }
      @media (max-width: 960px) {
        .area-hero__ttl__ja {
          font-size: 3.2rem; } }
    .area-hero__ttl__en {
      font-family: "Abhaya Libre", serif;
      font-size: 2.1rem;
      letter-spacing: .1em;
      font-weight: 400;
      margin: 24px 0 0; }
      @media (max-width: 960px) {
        .area-hero__ttl__en {
          font-size: 1.7rem;
          margin: 12px 0 0; } }

/* ========
breadcrumbs
======== */
.breadcrumbs {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px 24px;
  display: flex; }
  @media (max-width: 960px) {
    .breadcrumbs {
      padding: 4px 16px; } }
  .breadcrumbs:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    min-width: 1200px;
    background-color: rgba(0, 25, 45, 0.2);
    z-index: -1; }
  .breadcrumbs span, .breadcrumbs a {
    color: #fff;
    font-size: 1.3rem;
    letter-spacing: .06em;
    font-weight: 400; }
    @media (max-width: 960px) {
      .breadcrumbs span, .breadcrumbs a {
        font-size: 1.2rem; } }
  .breadcrumbs > span:nth-child(n+2):before {
    content: '―';
    margin: 0 1em; }

/* ========
mainvisual
======== */
.mainvisual {
  opacity: 0;
  width: 100%;
  height: 100vh;
  min-height: 600px;
  background: transparent url(../images/top/mainvisual.jpg) center center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end; }
  @media (max-width: 960px) {
    .mainvisual {
      display: block;
      height: auto;
      background: none; }
      .mainvisual:before {
        content: '';
        display: block;
        width: 100%;
        height: 80vw;
        background: transparent url(../images/top/mainvisual.jpg) center center no-repeat;
        background-size: cover; } }
  .mainvisual__textarea {
    text-align: right;
    padding: 80px; }
    @media (max-width: 960px) {
      .mainvisual__textarea {
        text-align: left;
        padding: 10px 24px 40px;
        background-color: #00192d; } }
  .mainvisual__ttl {
    opacity: 0;
    font-size: 10.8rem;
    display: block;
    margin: 0 0 24px; }
    @media (max-width: 960px) {
      .mainvisual__ttl {
        font-size: 7.6rem;
        margin: 0 0 4px; } }
    .mainvisual__ttl img {
      width: 3.5em;
      margin-left: auto; }
      @media (max-width: 960px) {
        .mainvisual__ttl img {
          margin-left: 0;
          margin-top: -1em; } }
  .mainvisual__copy {
    opacity: 0;
    font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
    color: #fff;
    font-size: 3.7rem;
    font-weight: 600;
    line-height: 1.66;
    letter-spacing: .1em;
    margin: 0 0 32px; }
    @media (max-width: 960px) {
      .mainvisual__copy {
        font-size: 2.8rem;
        margin: 0 0 16px; } }
    .mainvisual__copy span {
      display: block; }
  .mainvisual__desc {
    opacity: 0;
    font-family: "Abhaya Libre", serif;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: .1em;
    line-height: 2;
    color: #fff; }
    @media (max-width: 960px) {
      .mainvisual__desc {
        font-size: 1.3rem; } }
    .mainvisual__desc span {
      display: block; }
  .mainvisual.-animated {
    opacity: 1;
    transition: opacity 1.2s ease; }
    .mainvisual.-animated .mainvisual__ttl {
      opacity: 1;
      transition: 1.6s ease .5s; }
    .mainvisual.-animated .mainvisual__copy {
      opacity: 1;
      transition: 1.6s ease .8s; }
    .mainvisual.-animated .mainvisual__desc {
      opacity: 1;
      transition: 1.6s ease 1.1s; }

/* ========
section
======== */
.section-gray-bg {
  padding: 20px;
  position: relative;
  z-index: 0; }
  @media (max-width: 960px) {
    .section-gray-bg {
      padding: 24px 10px; } }
  .section-gray-bg:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    min-width: 1200px;
    background-color: #e6e6e6;
    z-index: -1; }

/* ========
lead-aboutus
======== */
.lead-aboutus {
  padding: 96px 0;
  background: transparent url(../images/common/bg_logo.png) right 0 bottom -120px no-repeat;
  background-size: 576px;
  position: relative;
  z-index: 0; }
  @media (max-width: 960px) {
    .lead-aboutus {
      padding: 48px 0;
      background-size: 278px;
      background-position: right -20px bottom -40px; } }
  .lead-aboutus .btn-basic {
    margin-top: 40px; }
    @media (max-width: 960px) {
      .lead-aboutus .btn-basic {
        margin-top: 32px;
        width: calc(100% - 20px); } }

/* ========
lead-case-study
======== */
.lead-case-study {
  padding: 80px 0; }
  @media (max-width: 960px) {
    .lead-case-study {
      padding: 40px 10px; } }
  .lead-case-study .l-inside > .btn-basic {
    margin-top: 10px; }
  .lead-case-study__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 30px; }
    @media (max-width: 960px) {
      .lead-case-study__list {
        padding: 0; } }
    .lead-case-study__list .item-note {
      margin: 0 10px 20px; }
      @media (min-width: 961px) {
        .lead-case-study__list .item-note {
          width: 445px; } }
      @media (max-width: 960px) {
        .lead-case-study__list .item-note {
          max-width: 445px;
          width: calc(100% - 16px);
          margin: 0 auto 20px; } }

/* ========
lead-facebook
======== */
.lead-facebook iframe {
  display: block;
  margin: 0 auto; }

/* ========
lead-info
======== */
.lead-info {
  padding: 80px 0; }
  @media (max-width: 960px) {
    .lead-info {
      padding: 40px 10px 50px; } }
  .lead-info__list li {
    border-bottom: 1px solid #d2d7dc; }
  .lead-info .btn-basic {
    margin: 30px auto 0; }
    @media (max-width: 960px) {
      .lead-info .btn-basic {
        margin: 20px auto 0;
        width: calc(100% - 50px); } }

/* ========
lead-note
======== */
.lead-note {
  overflow: hidden; }

/* ========
lead-service
======== */
.lead-service {
  padding: 80px 0 90px;
  position: relative;
  z-index: 0; }
  @media (max-width: 960px) {
    .lead-service {
      padding: 40px 0 50px; } }
  .lead-service.-content {
    padding: 40px 0; }
  .lead-service:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    min-width: 1200px;
    background-color: #00192d;
    z-index: -1; }
  .lead-service .l-inner > .btn-basic {
    margin: 30px auto 0; }
    @media (max-width: 960px) {
      .lead-service .l-inner > .btn-basic {
        margin: 20px auto 0;
        width: calc(100% - 50px);
        height: 70px; } }

/* ========
box-contact-link
======== */
.box-contact-link {
  background: transparent url(../images/common/bg_contact.jpg) center center no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  padding: 80px 0; }
  @media (max-width: 960px) {
    .box-contact-link {
      padding: 40px 10px; } }
  .box-contact-link__ttl {
    line-height: 1;
    margin: 0 0 24px; }
    .box-contact-link__ttl__en {
      font-family: "Abhaya Libre", serif;
      font-size: 4.3rem;
      letter-spacing: .1em;
      font-weight: 500; }
      @media (max-width: 960px) {
        .box-contact-link__ttl__en {
          font-size: 3.8rem; } }
    .box-contact-link__ttl__ja {
      font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: .1em; }
      @media (max-width: 960px) {
        .box-contact-link__ttl__ja {
          font-size: 1.3rem; } }
  .box-contact-link .btn-basic {
    margin: 40px auto 0; }
    @media (max-width: 960px) {
      .box-contact-link .btn-basic {
        margin-top: 24px; } }

/* ========
box-service-detail
======== */
.box-service-detail.-reverse .box-service-detail__main {
  flex-direction: row-reverse; }

@media (min-width: 961px) {
  .box-service-detail.-slim .box-service-detail__main__textarea {
    padding: 48px 0; } }

.box-service-detail__main {
  display: flex; }
  @media (max-width: 960px) {
    .box-service-detail__main {
      display: block; } }
  .box-service-detail__main__ph {
    width: 100%;
    background: transparent center center no-repeat;
    background-size: cover; }
    @media (max-width: 960px) {
      .box-service-detail__main__ph {
        height: 42vw; } }
  .box-service-detail__main__textarea {
    width: 565px;
    padding: 112px 0;
    flex: 0 0 auto;
    text-align: center;
    background-color: #fff; }
    @media (max-width: 960px) {
      .box-service-detail__main__textarea {
        width: 100%;
        padding: 24px 10px; } }
    @media (max-width: 960px) {
      .box-service-detail__main__textarea .desc-s {
        text-align: justify; } }

.box-service-detail__num {
  font-family: "Abhaya Libre", serif;
  font-size: 2rem;
  font-weight: 500;
  color: #a60019;
  letter-spacing: .08em;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.4;
  margin: 0 0 24px; }
  @media (max-width: 960px) {
    .box-service-detail__num {
      margin: 0 0 16px; } }
  .box-service-detail__num:after {
    font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
    counter-increment: serviceDetail 1;
    content: counter(serviceDetail, decimal-leading-zero);
    font-size: 3.3rem;
    margin: -6px 0 0 .25em; }

.box-service-detail__ttl {
  font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: 600;
  font-size: 2.6rem;
  letter-spacing: .1em;
  line-height: 1.2; }
  @media (max-width: 960px) {
    .box-service-detail__ttl {
      font-size: 2rem; } }

.box-service-detail__sub {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #f6f6f6;
  padding: 20px 15px; }
  @media (max-width: 960px) {
    .box-service-detail__sub {
      display: block;
      padding: 10px; } }
  .box-service-detail__sub__box {
    display: flex;
    background-color: #fff;
    width: calc(50% - 5px);
    margin: 0 5px; }
    @media (max-width: 960px) {
      .box-service-detail__sub__box {
        width: 100%;
        margin: 0;
        display: block; }
        .box-service-detail__sub__box:nth-child(n+2) {
          margin-top: 10px; } }
  .box-service-detail__sub__ph {
    width: 260px;
    min-height: 240px;
    background: transparent center center no-repeat;
    background-size: cover;
    flex: 0 0 auto; }
    @media (max-width: 960px) {
      .box-service-detail__sub__ph {
        width: 100%;
        min-height: 160px; } }
  .box-service-detail__sub__textarea {
    padding: 16px 20px;
    text-align: justify; }
  .box-service-detail__sub__list {
    width: 100%; }
    .box-service-detail__sub__list ul, .box-service-detail__sub__list ol {
      width: 100%;
      display: flex;
      justify-content: space-between; }
      @media (max-width: 960px) {
        .box-service-detail__sub__list ul, .box-service-detail__sub__list ol {
          display: block; } }
    .box-service-detail__sub__list li {
      width: calc(33.333% - 13.333px);
      background-color: #fff;
      padding: 16px 24px;
      margin: 0 5px;
      flex: 1 1 auto; }
      @media (max-width: 960px) {
        .box-service-detail__sub__list li {
          width: 100%;
          margin: 0; }
          .box-service-detail__sub__list li:nth-child(n+2) {
            margin-top: 10px; } }

.box-service-detail__copy {
  font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: .1em;
  line-height: 1.7; }
  @media (max-width: 960px) {
    .box-service-detail__copy {
      font-size: 1.6rem; } }

.box-service-detail .desc-xs span {
  font-size: 1.1rem; }

.box-service-detail .desc-xs a {
  text-decoration: underline; }

/* ========
box-service-link
======== */
.box-service-link {
  display: flex;
  background-color: #fff;
  margin-top: 40px; }
  @media (max-width: 960px) {
    .box-service-link {
      display: block; } }
  .box-service-link:first-child {
    margin-top: 0; }
  @media (min-width: 961px) {
    .box-service-link.-reverse {
      flex-direction: row-reverse; } }
  .box-service-link__ph {
    width: 100%;
    flex: 1 1 auto;
    background: transparent center center no-repeat;
    background-size: cover; }
    @media (max-width: 960px) {
      .box-service-link__ph {
        width: calc(100% - 10px);
        height: 62vw; } }
  .box-service-link__textarea {
    width: 475px;
    flex: 0 0 auto;
    padding: 84px 5px 5px; }
    @media (max-width: 960px) {
      .box-service-link__textarea {
        width: 100%;
        padding: 16px 5px 5px; } }
    .box-service-link__textarea .btn-basic {
      width: 100%;
      margin-top: 72px; }
      @media (max-width: 960px) {
        .box-service-link__textarea .btn-basic {
          margin-top: 20px;
          height: 50px; } }
  .box-service-link__ttl {
    font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
    font-size: 2.4rem;
    font-weight: 600;
    letter-spacing: .1em;
    text-align: center;
    line-height: 1.8;
    margin: 0 0 32px; }
    @media (max-width: 960px) {
      .box-service-link__ttl {
        font-size: 2.1rem;
        margin: 0 0 12px; } }
    .box-service-link__ttl span {
      display: block;
      font-family: "Abhaya Libre", serif;
      font-size: 1.6rem;
      color: #a60019;
      font-weight: 500;
      margin: 4px 0 0; }
      @media (max-width: 960px) {
        .box-service-link__ttl span {
          margin: 0; } }
  .box-service-link__copy {
    font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
    font-size: 1.8rem;
    letter-spacing: .1em;
    line-height: 1.66;
    font-weight: 500;
    text-align: center;
    margin: 0 0 16px; }
    @media (max-width: 960px) {
      .box-service-link__copy {
        font-size: 1.7rem; } }
  .box-service-link__desc {
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: .06em;
    text-align: center; }

/* ========
list-info
======== */
.list-info {
  padding: 100px 0; }
  @media (max-width: 960px) {
    .list-info {
      padding: 40px 0; } }
  .list-info .l-inside {
    padding: 0 40px; }
    @media (max-width: 960px) {
      .list-info .l-inside {
        padding: 0 10px; } }
  .list-info__list {
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 961px) {
      .list-info__list .item-note {
        width: 445px;
        margin: 20px 0 0; }
        .list-info__list .item-note:nth-child(2n) {
          margin-left: 20px; } }
    @media (max-width: 960px) {
      .list-info__list .item-note {
        max-width: 445px;
        width: calc(100% - 16px);
        margin: 0 auto 20px; } }
    .list-info__list .item-info {
      width: 100%;
      border-bottom: 1px solid #d2d7dc; }

/* ========
list-text
======== */
.list-text {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .list-text ul li {
    margin-left: 2em;
    text-indent: -2em; }
    .list-text ul li:before {
      content: 'ー、';
      color: #a60019; }
  .list-text ol {
    counter-reset: listText; }
    .list-text ol li {
      margin-left: 1.25em;
      text-indent: -1.25em; }
      .list-text ol li:before {
        counter-increment: listText 1;
        content: counter(listText) ". ";
        color: #a60019; }
  .list-text li {
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: .06em;
    line-height: 2.1; }
    @media (max-width: 960px) {
      .list-text li {
        line-height: 1.66; } }
    @media (max-width: 960px) {
      .list-text li + li {
        margin-top: .6em; } }

/* ========
tab-link
======== */
.tab-link ul {
  display: flex;
  padding: 0 0 2px;
  border-bottom: 2px solid #a60019;
  height: 64px; }
  @media (max-width: 960px) {
    .tab-link ul {
      height: 54px; } }

.tab-link li {
  width: 260px;
  margin: 0 5px 0 0; }
  @media (max-width: 960px) {
    .tab-link li {
      width: 110px;
      height: 50px; } }
  .tab-link li.-current {
    height: 62px; }
    @media (max-width: 960px) {
      .tab-link li.-current {
        height: 52px; } }
    .tab-link li.-current a {
      background-color: #a60019; }
  .tab-link li a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: .06em;
    background-color: #6e7882; }
    @media (max-width: 960px) {
      .tab-link li a {
        font-size: 1.3rem;
        text-align: center;
        line-height: 1.4;
        padding: 2px 4px; } }

/* ========
item-info
======== */
.item-info a {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 24px 0; }
  @media (max-width: 960px) {
    .item-info a {
      display: block;
      padding: 16px 5px 16px 10px; } }

.item-info__meta {
  display: flex;
  align-items: center;
  margin: 0 20px 0 0; }
  @media (max-width: 960px) {
    .item-info__meta {
      margin: 0 0 8px; } }

.item-info__date {
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: .06em;
  width: 94px; }

.item-info__tag {
  width: 110px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  background-color: #a60019;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: .06em; }
  @media (max-width: 960px) {
    .item-info__tag {
      height: 25px;
      font-size: 1.2rem; } }

.item-info__ttl {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: .06em; }
  @media (max-width: 960px) {
    .item-info__ttl {
      line-height: 1.5; } }

/* ========
item-note
======== */
.item-note {
  width: 335px;
  background-color: #00192d;
  padding: 0 0 10px;
  position: relative;
  z-index: 0; }
  @media (max-width: 960px) {
    .item-note {
      width: 265px; } }
  .item-note .btn-basic {
    width: calc(100% - 20px);
    height: 50px;
    margin: 0 auto; }
    @media (max-width: 960px) {
      .item-note .btn-basic {
        height: 40px; } }
    .item-note .btn-basic a {
      font-size: 1.8rem; }
      @media (min-width: 961px) {
        .item-note .btn-basic a:hover:before {
          right: 40px; } }
      @media (max-width: 960px) {
        .item-note .btn-basic a {
          font-size: 1.6rem; } }
      .item-note .btn-basic a:before {
        right: 20px; }
  .item-note__tag {
    position: absolute;
    top: 15px;
    right: 0;
    background-color: #a60019;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: .06em;
    line-height: 1;
    width: 120px;
    padding: 8px 0 8px 8px;
    text-align: center;
    border-radius: 60px 0 0 60px; }
    @media (max-width: 960px) {
      .item-note__tag {
        font-size: 1.2rem; } }
  .item-note__img {
    padding: 0 20px 0 0; }
  .item-note__textarea {
    padding: 16px 20px 20px; }
    @media (max-width: 960px) {
      .item-note__textarea {
        padding: 20px 24px; } }
  .item-note__date {
    font-size: 1.3rem;
    letter-spacing: .06em;
    font-weight: 400;
    color: #fff; }
    @media (max-width: 960px) {
      .item-note__date {
        font-size: 1.2rem; } }
  .item-note__ttl {
    font-size: 2.1rem;
    letter-spacing: .06em;
    font-weight: 500;
    color: #fff;
    line-height: 1.56;
    margin: 8px 0; }
    @media (max-width: 960px) {
      .item-note__ttl {
        font-size: 1.8rem; } }
  .item-note__desc {
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: .06em;
    color: #fff;
    text-align: justify;
    line-height: 1.7; }
    @media (max-width: 960px) {
      .item-note__desc {
        font-size: 1.2rem; } }

/* ========
unit-access
======== */
.unit-access {
  padding: 80px 20px 90px;
  position: relative;
  z-index: 0; }
  @media (max-width: 960px) {
    .unit-access {
      padding: 40px 10px; } }
  .unit-access .ttl-type02 {
    margin-bottom: 32px; }
  .unit-access:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    min-width: 1200px;
    background-color: #e6e6e6;
    z-index: -1; }
  .unit-access__col {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 960px) {
      .unit-access__col {
        display: block; } }
  .unit-access__box {
    width: calc(50% - 10px);
    background-color: #fff; }
    @media (max-width: 960px) {
      .unit-access__box {
        width: 100%;
        margin-top: 10px; } }
  .unit-access__map iframe {
    display: block;
    width: 100%;
    height: 390px; }
    @media (max-width: 960px) {
      .unit-access__map iframe {
        height: 220px; } }
  .unit-access__textarea {
    padding: 32px;
    text-align: center; }
    @media (max-width: 960px) {
      .unit-access__textarea {
        padding: 24px; } }
  .unit-access__name {
    font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
    font-size: 2.4rem;
    font-weight: 600;
    letter-spacing: .1em;
    line-height: 1.4;
    margin: 0 0 16px; }
    @media (max-width: 960px) {
      .unit-access__name {
        font-size: 2rem;
        margin: 0 0 8px; } }
  .unit-access__desc {
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: .06em;
    line-height: 2; }

/* ========
unit-col2box
======== */
.unit-col2box {
  display: flex;
  justify-content: space-between;
  background-color: #e6e6e6;
  padding: 20px; }
  @media (max-width: 960px) {
    .unit-col2box {
      padding: 10px;
      display: block; } }
  .unit-col2box > * {
    width: calc(50% - 10px);
    flex: 0 0 auto;
    background-color: #fff;
    padding: 50px 0; }
    @media (max-width: 960px) {
      .unit-col2box > * {
        width: 100%;
        margin-top: 10px;
        padding: 20px 0; }
        .unit-col2box > *:first-child {
          margin-top: 0; } }

/* ========
unit-contact
======== */
.unit-contact {
  padding: 80px 0; }
  @media (max-width: 960px) {
    .unit-contact {
      padding: 40px 0; } }
  .unit-contact .l-inside {
    padding: 0 40px;
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: .06em; }
    @media (max-width: 960px) {
      .unit-contact .l-inside {
        padding: 0 10px; } }

/* ========
unit-corporate
======== */
.unit-corporate {
  padding: 80px 0 100px; }
  .unit-corporate .ttl-type02 {
    margin-bottom: 32px; }
  .unit-corporate__table {
    width: 100%; }
    @media (max-width: 960px) {
      .unit-corporate__table {
        width: calc(100% - 40px);
        margin: 0 auto; } }
    .unit-corporate__table tbody {
      width: 100%; }
    .unit-corporate__table tr {
      border-top: 1px solid #d2d7dc;
      border-bottom: 1px solid #d2d7dc; }
    .unit-corporate__table th, .unit-corporate__table td {
      letter-spacing: .06em;
      vertical-align: top;
      text-align: justify;
      line-height: 1.8;
      padding: 20px 0; }
      @media (max-width: 960px) {
        .unit-corporate__table th, .unit-corporate__table td {
          padding: 12px 0; } }
      .unit-corporate__table th a, .unit-corporate__table td a {
        color: #0064b4; }
        @media (min-width: 961px) {
          .unit-corporate__table th a:hover, .unit-corporate__table td a:hover {
            opacity: 1;
            text-decoration: underline; } }
    .unit-corporate__table th {
      font-size: 1.6rem;
      font-weight: 500;
      padding-right: 40px;
      text-align: left; }
      @media (max-width: 960px) {
        .unit-corporate__table th {
          font-size: 1.5rem;
          width: 120px;
          padding-right: 16px; } }
    .unit-corporate__table td {
      font-size: 1.4rem;
      font-weight: 400; }
      @media (max-width: 960px) {
        .unit-corporate__table td {
          font-size: 1.3rem;
          width: calc(100% - 120px); } }

/* ========
unit-greeting
======== */
.unit-greeting__head {
  position: relative;
  z-index: 0;
  padding: 96px 0 196px; }
  @media (max-width: 960px) {
    .unit-greeting__head {
      padding: 56px 0 88px; } }
  .unit-greeting__head:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -210px;
    right: -210px;
    bottom: 0;
    background: transparent url(../images/company/img_company01.jpg) center center no-repeat;
    background-size: cover;
    z-index: -1; }
  .unit-greeting__head .ttl-type03 {
    color: #fff; }
    .unit-greeting__head .ttl-type03:after {
      content: none; }

.unit-greeting__ttl {
  color: #fff;
  text-align: center; }
  .unit-greeting__ttl:after {
    content: '';
    display: block;
    width: 3px;
    height: 20px;
    background-color: #fff;
    margin: 16px auto 12px; }
    @media (max-width: 960px) {
      .unit-greeting__ttl:after {
        width: 2px;
        height: 16px; } }
  .unit-greeting__ttl__en {
    font-family: "Abhaya Libre", serif;
    font-size: 5rem;
    font-weight: 500;
    letter-spacing: .08em;
    line-height: 1.2; }
    @media (max-width: 960px) {
      .unit-greeting__ttl__en {
        font-size: 3.6rem; } }
  .unit-greeting__ttl__ja {
    font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .1em;
    line-height: 1.2; }

.unit-greeting__main {
  padding: 20px;
  background-color: #e6e6e6; }
  @media (max-width: 960px) {
    .unit-greeting__main {
      padding: 10px; } }

.unit-greeting__content {
  background-color: #fff;
  padding: 56px 0;
  position: relative;
  z-index: 1;
  margin-top: -120px; }
  @media (max-width: 960px) {
    .unit-greeting__content {
      text-align: left;
      margin-top: -72px;
      padding: 20px; } }
  .unit-greeting__content .l-inside {
    display: flex; }
    @media (max-width: 960px) {
      .unit-greeting__content .l-inside {
        display: block; } }

.unit-greeting__ph {
  width: 340px;
  margin: 0 40px 0 0;
  flex: 0 0 auto; }
  @media (max-width: 960px) {
    .unit-greeting__ph {
      max-width: 100%;
      width: 100%;
      margin: 0 auto 32px; }
      .unit-greeting__ph img {
        margin: 0 auto; } }

.unit-greeting__textarea .desc-m {
  line-height: 1.9;
  text-indent: 1em; }
  .unit-greeting__textarea .desc-m:first-child {
    margin-top: 0; }

.unit-greeting__name {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: .06em;
  margin-top: 32px; }
  .unit-greeting__name img {
    font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
    font-size: 3.4rem;
    font-weight: 700;
    line-height: 1;
    height: 1em;
    margin-left: .5em; }
    @media (max-width: 960px) {
      .unit-greeting__name img {
        font-size: 2.8rem; } }

/* ========
unit-icon-content
======== */
.unit-icon-content {
  position: relative;
  z-index: 0;
  padding: 20px; }
  @media (max-width: 960px) {
    .unit-icon-content {
      padding: 24px 10px; } }
  .unit-icon-content:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    min-width: 1200px;
    background-color: #e6e6e6;
    z-index: -1; }
  .unit-icon-content .l-inner {
    background-color: #fff;
    padding: 50px 0 60px; }
    @media (max-width: 960px) {
      .unit-icon-content .l-inner {
        padding: 40px 0; } }
  .unit-icon-content__list {
    margin: 32px 0 0; }
    .unit-icon-content__list > ul {
      display: flex;
      justify-content: center; }
      @media (max-width: 960px) {
        .unit-icon-content__list > ul {
          display: block; } }
      .unit-icon-content__list > ul > li {
        width: 312px;
        padding: 0 26px; }
        @media (max-width: 960px) {
          .unit-icon-content__list > ul > li {
            width: 100%; } }
        .unit-icon-content__list > ul > li:nth-child(n+2) {
          border-left: 1px solid #e6e6e6; }
          @media (max-width: 960px) {
            .unit-icon-content__list > ul > li:nth-child(n+2) {
              border-left: none;
              margin-top: 32px; } }
        .unit-icon-content__list > ul > li .desc-s {
          text-align: center;
          margin-top: 0; }
          .unit-icon-content__list > ul > li .desc-s:before {
            content: '';
            display: block;
            width: 1px;
            height: 8px;
            background-color: #a60019;
            margin: 8px auto; }
        .unit-icon-content__list > ul > li ul {
          margin: 20px 0 0; }
        .unit-icon-content__list > ul > li li:nth-child(n+2) {
          margin: 16px 0 0; }
        .unit-icon-content__list > ul > li li p {
          margin-left: 1.66em;
          text-indent: -1.66em;
          font-size: 1.3rem;
          font-weight: 400;
          letter-spacing: .06em;
          line-height: 1.5; }
          .unit-icon-content__list > ul > li li p:before {
            content: '';
            display: inline-block;
            width: 15px;
            height: 15px;
            background: transparent url(../images/service/icon_check.png) center center no-repeat;
            background-size: cover;
            margin: 0 .5em 0 0;
            transform: translate(0, 3px); }
  .unit-icon-content__icon {
    background-color: #00192d;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 16px; }
    @media (max-width: 960px) {
      .unit-icon-content__icon {
        width: 120px;
        height: 120px; } }
    .unit-icon-content__icon img {
      height: 48%; }

/* ========
unit-mission
======== */
.unit-mission {
  padding: 80px 0 90px;
  position: relative;
  z-index: 0; }
  @media (max-width: 960px) {
    .unit-mission {
      padding: 40px 0; } }
  .unit-mission:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    min-width: 1200px;
    background-color: #00192d;
    z-index: -1; }
  .unit-mission .ttl-type02 {
    margin-bottom: 32px; }
  .unit-mission .ttl-type03:after {
    content: none; }
  .unit-mission__col2 {
    display: flex;
    justify-content: space-between;
    margin: 40px 0 0; }
    @media (max-width: 960px) {
      .unit-mission__col2 {
        display: block;
        padding: 0 10px; } }
    .unit-mission__col2 .unit-mission__box {
      width: calc(50% - 10px); }
      @media (max-width: 960px) {
        .unit-mission__col2 .unit-mission__box {
          width: 100%; } }
  .unit-mission__col1 {
    display: block;
    margin: 20px 0 0; }
    @media (max-width: 960px) {
      .unit-mission__col1 {
        margin: 0;
        padding: 0 10px; } }
  .unit-mission__ttl {
    color: #00192d;
    text-align: center;
    margin: 0 0 24px; }
    .unit-mission__ttl__en {
      font-family: "Abhaya Libre", serif;
      font-size: 4.3rem;
      font-weight: 500;
      letter-spacing: .08em;
      line-height: 1.2; }
      @media (max-width: 960px) {
        .unit-mission__ttl__en {
          font-size: 3.6rem; } }
    .unit-mission__ttl__ja {
      font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: .1em;
      line-height: 1.2; }
      @media (max-width: 960px) {
        .unit-mission__ttl__ja {
          font-size: 1.3rem; } }
  .unit-mission__box {
    background-color: #fff;
    padding: 40px 36px 56px; }
    @media (max-width: 960px) {
      .unit-mission__box {
        padding: 32px 20px;
        margin-top: 10px; } }
  .unit-mission__list {
    counter-reset: listNum; }
    .unit-mission__list.-one li {
      margin-left: 2.15em;
      text-indent: -2.15em; }
      .unit-mission__list.-one li:before {
        content: 'ー、';
        font-weight: 400; }
    .unit-mission__list li {
      font-size: 1.5rem;
      font-weight: 500;
      letter-spacing: .06em;
      line-height: 1.55;
      margin-left: 1.4em;
      text-indent: -1.4em; }
      @media (max-width: 960px) {
        .unit-mission__list li {
          font-size: 1.4rem; } }
      .unit-mission__list li:nth-child(n+2) {
        margin-top: 0.66em; }
      .unit-mission__list li:before {
        counter-increment: listNum 1;
        content: counter(listNum) ". ";
        font-weight: 700;
        color: #a60019; }

/* ========
unit-modal
======== */
.unit-modal {
  position: fixed;
  z-index: 1501;
  width: 800px;
  height: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  @media (max-width: 960px) {
    .unit-modal {
      width: 90%;
      max-width: 540px;
      max-height: 260px; } }
  .unit-modal iframe {
    display: block;
    width: 100%;
    height: 100%; }
  .unit-modal__close {
    display: block;
    position: absolute;
    top: -48px;
    right: -48px;
    width: 40px;
    height: 40px;
    cursor: pointer; }
    @media (max-width: 960px) {
      .unit-modal__close {
        right: 0; } }
    .unit-modal__close:before, .unit-modal__close:after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto; }
    .unit-modal__close:before {
      transform: rotate(45deg); }
    .unit-modal__close:after {
      transform: rotate(-45deg); }

.screen-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 6, 10, 0.8);
  z-index: 1500; }

/* ========
unit-movie
======== */
.unit-movie {
  background-color: #fff;
  padding: 48px 0 32px;
  overflow: hidden; }

/* ========
unit-partner
======== */
.unit-partner {
  background-color: #fff;
  padding: 48px 0 32px;
  margin: 20px 0 0; }
  @media (max-width: 960px) {
    .unit-partner {
      padding: 32px 0 24px; } }
  .unit-partner__ttl {
    font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
    font-size: 3.5rem;
    letter-spacing: .1em;
    font-weight: 600;
    text-align: center;
    line-height: 1.4;
    margin: 0 0 20px; }
    @media (max-width: 960px) {
      .unit-partner__ttl {
        font-size: 2.6rem; } }
  .unit-partner__list {
    width: 926px;
    margin: 0 auto; }
    @media (max-width: 960px) {
      .unit-partner__list {
        width: 100%;
        padding: 0 5px; } }
    .unit-partner__list ul {
      display: flex;
      flex-wrap: wrap; }
    .unit-partner__list li {
      margin: 0 5px 10px;
      width: calc(25% - 10px);
      height: 128px;
      background-color: #f6f6f6;
      position: relative; }
      @media (max-width: 960px) {
        .unit-partner__list li {
          width: calc(50% - 10px);
          margin: 0 5px 10px; } }
      .unit-partner__list li span {
        display: block;
        position: absolute;
        background: transparent center center no-repeat;
        background-size: contain;
        top: 8%;
        left: 12%;
        right: 12%;
        bottom: 8%; }

/* ========
unit-pickup
======== */
.unit-pickup {
  padding: 80px 0;
  background-color: #e6e6e6; }
  @media (max-width: 960px) {
    .unit-pickup {
      padding: 40px 0; } }
  .unit-pickup .ttl-type02 {
    margin: 0 0 32px; }
  .unit-pickup__content {
    display: flex;
    justify-content: center; }
    @media (max-width: 960px) {
      .unit-pickup__content {
        display: block;
        padding: 0 10px; } }
    .unit-pickup__content .item-note {
      width: 445px;
      margin: 0 10px; }
      @media (max-width: 960px) {
        .unit-pickup__content .item-note {
          width: 100%;
          max-width: 445px;
          margin: 0 auto 10px; } }

/* ========
unit-school-link
======== */
.unit-school-link {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; }
  @media (max-width: 960px) {
    .unit-school-link {
      display: block;
      margin-top: 10px; } }
  .unit-school-link__box {
    width: calc(50% - 10px);
    background-color: #fff;
    text-align: center;
    padding: 88px 0; }
    @media (max-width: 960px) {
      .unit-school-link__box {
        display: block;
        width: 100%;
        padding: 24px 10px; }
        .unit-school-link__box:nth-child(n+2) {
          margin-top: 10px; } }
  .unit-school-link__logo {
    width: 118px;
    height: 110px;
    margin: 0 auto 24px; }
    @media (max-width: 960px) {
      .unit-school-link__logo {
        width: 96px;
        height: auto;
        margin: 0 auto 16px; } }
  .unit-school-link__ttl {
    text-align: center; }
    .unit-school-link__ttl__ja {
      font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
      font-size: 2.4rem;
      font-weight: 600;
      letter-spacing: .1em;
      line-height: 1.4; }
      @media (max-width: 960px) {
        .unit-school-link__ttl__ja {
          font-size: 2rem; } }
    .unit-school-link__ttl__en {
      font-family: "Abhaya Libre", serif;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.4;
      letter-spacing: .08em;
      color: #a60019;
      margin: 8px 0 0; }
      @media (max-width: 960px) {
        .unit-school-link__ttl__en {
          font-size: 1.4rem; } }
  .unit-school-link .btn-basic {
    margin-top: 24px; }

/* ========
unit-service-detail
======== */
.unit-service-detail {
  padding: 40px;
  counter-reset: serviceDetail;
  position: relative;
  z-index: 0; }
  @media (max-width: 960px) {
    .unit-service-detail {
      padding: 24px 10px; } }
  .unit-service-detail:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    min-width: 1200px;
    background-color: #00192d;
    z-index: -1; }
  .unit-service-detail .box-service-detail:nth-child(n+2) {
    margin-top: 30px; }

/* ========
unit-text-base
======== */
.unit-text-base {
  padding: 80px 0; }
  @media (max-width: 960px) {
    .unit-text-base {
      padding: 40px 0; } }
  .unit-text-base .l-inside {
    padding: 0 40px;
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: .06em; }
    @media (max-width: 960px) {
      .unit-text-base .l-inside {
        padding: 0 10px; } }
  .unit-text-base__grp:nth-child(n+2) {
    margin-top: 80px; }
    @media (max-width: 960px) {
      .unit-text-base__grp:nth-child(n+2) {
        margin-top: 40px; } }
  .unit-text-base h1, .unit-text-base h2 {
    font-size: 2.3rem;
    font-weight: 500;
    letter-spacing: .06em;
    border-left: 3px solid #a60019;
    line-height: 1.4;
    padding: 0 0 0 .75em;
    margin: 2em 0 1em; }
    @media (max-width: 960px) {
      .unit-text-base h1, .unit-text-base h2 {
        font-size: 1.8rem; } }
  .unit-text-base h3 {
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: .06em;
    color: #a60019;
    line-height: 1.7;
    margin: 1em 0; }
    @media (max-width: 960px) {
      .unit-text-base h3 {
        font-size: 1.6rem; } }
  .unit-text-base h4 {
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .06em; }
    @media (max-width: 960px) {
      .unit-text-base h4 {
        font-size: 1.5rem; } }
  .unit-text-base h5 {
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: .06em; }
    @media (max-width: 960px) {
      .unit-text-base h5 {
        font-size: 1.4rem; } }
  .unit-text-base h6 {
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .06em; }
    @media (max-width: 960px) {
      .unit-text-base h6 {
        font-size: 1.3rem; } }
  .unit-text-base i, .unit-text-base em {
    font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif; }
  .unit-text-base ul {
    list-style-type: disc;
    padding: 0 0 0 2em;
    margin: 1em 0; }
    .unit-text-base ul li {
      list-style-type: disc; }
  .unit-text-base ol {
    list-style-type: decimal;
    padding: 0 0 0 2em;
    margin: 1em 0; }
    .unit-text-base ol li {
      list-style-type: decimal; }
  .unit-text-base p {
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: .06em;
    line-height: 1.8;
    margin: 1em 0; }
  .unit-text-base blockquote {
    color: #505050;
    background-color: #f6f6f6;
    padding: 24px 32px;
    margin: 10px 0; }
    @media (max-width: 960px) {
      .unit-text-base blockquote {
        padding: 16px; } }

/* ========
slider-movie
======== */
.slider-movie {
  width: 496px;
  margin: 0 auto; }
  @media (max-width: 960px) {
    .slider-movie {
      width: 100%; } }
  .slider-movie .slick-list {
    overflow: visible !important; }
  .slider-movie__item {
    width: 480px;
    position: relative;
    z-index: 0;
    background-color: #00192d;
    margin: 0 8px;
    cursor: pointer; }
    .slider-movie__item img {
      opacity: .8; }
    .slider-movie__item:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      height: 100px;
      margin: auto;
      background: transparent center center no-repeat;
      background-size: contain;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' overflow='scroll' viewBox='0 0 200 200'%3E%3Cdefs/%3E%3Cstyle%3E.tri{fill:%23fff}%3C/style%3E%3Cpath d='M100 4c52.93457 0 96 43.06543 96 96s-43.06543 96-96 96-96-43.06543-96-96S47.06543 4 100 4m0-4C44.77153 0 0 44.77153 0 100s44.77153 100 100 100 100-44.77151 100-100S155.22845 0 100 0z' class='tri'/%3E%3Cpath d='M145.66878 96.23975L77.05927 56.46854c-2.65662-1.53998-5.98344.37689-5.98344 3.4476v80.11757c0 3.0818 3.34844 4.99722 6.00495 3.43504l68.60951-40.34637c2.62946-1.54626 2.61756-5.35283-.02151-6.88263z' class='tri'/%3E%3C/svg%3E");
      z-index: 2; }
      @media (max-width: 960px) {
        .slider-movie__item:after {
          width: 64px;
          height: 64px; } }
  .slider-movie__desc {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 25, 45, 0.2);
    z-index: 1;
    color: #fff;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .06em;
    padding: 8px 16px; }
    @media (max-width: 960px) {
      .slider-movie__desc {
        font-size: 1.3rem; } }
  .slider-movie .slick-dots {
    display: flex;
    justify-content: center;
    margin: 24px 0 0; }
    .slider-movie .slick-dots li {
      cursor: pointer;
      display: block;
      position: relative;
      padding: 2px;
      margin: 0 10px; }
      @media (max-width: 960px) {
        .slider-movie .slick-dots li {
          margin: 0 4px; } }
      .slider-movie .slick-dots li.slick-active:before {
        background-color: #00192d; }
      .slider-movie .slick-dots li:before {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        background-color: #bac0c4;
        border-radius: 50%;
        transition: .2s ease; }
        @media (max-width: 960px) {
          .slider-movie .slick-dots li:before {
            width: 10px;
            height: 10px; } }
      .slider-movie .slick-dots li button {
        display: none; }

/* ========
slider-note
======== */
.slider-note {
  width: 345px;
  margin: 0 auto;
  position: relative;
  z-index: 0; }
  @media (max-width: 960px) {
    .slider-note {
      width: 275px; } }
  .slider-note .item-note {
    margin: 0 5px; }
    @media (min-width: 961px) {
      .slider-note .item-note__desc {
        min-height: 5.1em; } }
  .slider-note .slick-list {
    overflow: visible; }
  .slider-note__arrow {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #a60019;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    cursor: pointer;
    z-index: 3; }
    @media (min-width: 961px) {
      .slider-note__arrow {
        transition: .4s ease; }
        .slider-note__arrow:hover {
          background-color: #920016; } }
    @media (max-width: 960px) {
      .slider-note__arrow {
        width: 45px;
        height: 45px; } }
    .slider-note__arrow:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7.5px 20px 7.5px 0;
      border-color: transparent #fff transparent transparent;
      position: absolute;
      top: 0;
      left: 0;
      right: 10%;
      bottom: 0;
      margin: auto; }
      @media (max-width: 960px) {
        .slider-note__arrow:before {
          border-width: 6px 15px 6px 0; } }
    .slider-note__arrow.-prev {
      left: 0;
      transform: translate(-50%, 0); }
    .slider-note__arrow.-next {
      right: 0;
      transform: translate(50%, 0) rotate(180deg); }

/* ========
intro-area
======== */
.intro-area {
  padding: 96px 0;
  background: transparent url(../images/common/bg_logo.png) right 0 bottom -214px no-repeat;
  background-size: 576px;
  position: relative;
  z-index: 0; }
  @media (max-width: 960px) {
    .intro-area {
      padding: 48px 20px;
      background-size: 278px;
      background-position: right -20px bottom -40px; } }

/* ========
intro-simple
======== */
.intro-simple {
  padding: 80px 0; }
  @media (max-width: 960px) {
    .intro-simple {
      padding: 40px 10px; } }
  .intro-simple .desc-l {
    text-align: center; }

/* ========
nav-pager00000
======== */
.nav-pager {
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center; }
  .nav-pager .page-numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px; }
    .nav-pager .page-numbers:not([class*="prev"]):not([class*="next"]) {
      width: 50px;
      height: 50px;
      border: 1px solid #6e7882;
      font-size: 1.8rem;
      font-weight: 500;
      letter-spacing: .06em;
      color: #6e7882; }
      .nav-pager .page-numbers:not([class*="prev"]):not([class*="next"]).current {
        color: #a60019;
        border: 2px solid #a60019; }
    .nav-pager .page-numbers.prev, .nav-pager .page-numbers.next {
      margin: 0 25px; }
  .nav-pager > span {
    margin: 0 25px;
    opacity: .3; }
  .nav-pager ul {
    display: flex;
    justify-content: center;
    align-items: center; }
  .nav-pager__arrow {
    width: 120px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6e7882;
    color: #fff;
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: .06em; }

/* ========
detail-info
======== */
.detail-info {
  padding: 100px 0; }
  @media (max-width: 960px) {
    .detail-info {
      padding: 40px 0; } }
  .detail-info .l-inside {
    padding: 0 40px; }
    @media (max-width: 960px) {
      .detail-info .l-inside {
        padding: 0 10px; } }
  .detail-info__meta {
    display: flex;
    align-items: center; }
  .detail-info__date {
    font-size: 1.3rem;
    letter-spacing: .06em; }
  .detail-info__tag {
    width: 140px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    background-color: #a60019;
    color: #fff;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: .06em;
    margin: 0 0 0 1em; }
  .detail-info__ttl {
    font-size: 2.9rem;
    font-weight: 700;
    letter-spacing: .06em;
    border-bottom: 2px solid #a60019;
    line-height: 1.5;
    padding: 8px 0 16px; }
    @media (max-width: 960px) {
      .detail-info__ttl {
        font-size: 2rem; } }
  .detail-info__content {
    padding: 20px 60px 40px;
    text-align: justify; }
    @media (max-width: 960px) {
      .detail-info__content {
        padding: 16px 0 32px; } }
    .detail-info__content h1, .detail-info__content h2 {
      font-size: 2.3rem;
      font-weight: 500;
      letter-spacing: .06em;
      border-left: 3px solid #a60019;
      line-height: 1.4;
      padding: 0 0 0 .75em;
      margin: 2em 0 1em; }
      @media (max-width: 960px) {
        .detail-info__content h1, .detail-info__content h2 {
          font-size: 1.8rem; } }
    .detail-info__content h3 {
      font-size: 1.8rem;
      font-weight: 500;
      letter-spacing: .06em;
      color: #a60019;
      line-height: 1.7;
      margin: 1em 0; }
      @media (max-width: 960px) {
        .detail-info__content h3 {
          font-size: 1.6rem; } }
    .detail-info__content h4 {
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: .06em; }
      @media (max-width: 960px) {
        .detail-info__content h4 {
          font-size: 1.5rem; } }
    .detail-info__content h5 {
      font-size: 1.5rem;
      font-weight: 500;
      letter-spacing: .06em; }
      @media (max-width: 960px) {
        .detail-info__content h5 {
          font-size: 1.4rem; } }
    .detail-info__content h6 {
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: .06em; }
      @media (max-width: 960px) {
        .detail-info__content h6 {
          font-size: 1.3rem; } }
    .detail-info__content i, .detail-info__content em {
      font-family: "Noto Serif JP", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif; }
    .detail-info__content ul {
      list-style-type: disc;
      padding: 0 0 0 2em;
      margin: 1em 0; }
      .detail-info__content ul li {
        list-style-type: disc; }
    .detail-info__content ol {
      list-style-type: decimal;
      padding: 0 0 0 2em;
      margin: 1em 0; }
      .detail-info__content ol li {
        list-style-type: decimal; }
    .detail-info__content a {
      color: #0064b4; }
      @media (min-width: 961px) {
        .detail-info__content a:hover {
          opacity: 1;
          text-decoration: underline; } }
    .detail-info__content p {
      font-size: 1.4rem;
      font-weight: 400;
      letter-spacing: .06em;
      line-height: 1.8;
      margin: 1em 0; }
    .detail-info__content blockquote {
      color: #505050;
      background-color: #f6f6f6;
      padding: 24px 32px;
      margin: 10px 0; }
      @media (max-width: 960px) {
        .detail-info__content blockquote {
          padding: 16px; } }
  .detail-info__thumb {
    margin: 0 0 56px; }
    @media (max-width: 960px) {
      .detail-info__thumb {
        margin: 0 0 32px; } }
  .detail-info__btns {
    display: flex;
    justify-content: center; }
    @media (max-width: 960px) {
      .detail-info__btns {
        display: block; } }
    .detail-info__btns .btn-basic {
      width: 340px;
      margin: 0 10px; }
      @media (max-width: 960px) {
        .detail-info__btns .btn-basic {
          width: 100%;
          max-width: 340px;
          margin: 0 auto 10px; } }
  .detail-info__share {
    background-color: #f6f6f6;
    border: 1px solid #ececec;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 64px 0 32px; }
    @media (max-width: 960px) {
      .detail-info__share {
        margin: 32px 0 24px;
        padding: 8px; } }
    .detail-info__share__label {
      display: flex;
      align-items: center;
      padding: 0 0 0 5px; }
      .detail-info__share__label figure {
        display: block;
        width: 20px;
        margin: 0 10px 0 0; }
        @media (max-width: 960px) {
          .detail-info__share__label figure {
            width: 16px; } }
      .detail-info__share__label p {
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: .06em; }
        @media (max-width: 960px) {
          .detail-info__share__label p {
            font-size: 1.4rem; } }
    .detail-info__share__links {
      display: flex; }
      .detail-info__share__links a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background-color: #ececec;
        margin: 0 0 0 10px; }
        @media (max-width: 960px) {
          .detail-info__share__links a {
            width: 32px;
            height: 32px;
            margin: 0 0 0 8px; } }
        .detail-info__share__links a.-facebook {
          background-color: #0050a0; }
          .detail-info__share__links a.-facebook img {
            width: 32%; }
        .detail-info__share__links a.-twitter {
          background-color: #00a0f0; }
          .detail-info__share__links a.-twitter img {
            width: 50%; }
  .detail-info__back {
    width: 180px;
    height: 50px;
    margin: 0 auto; }
    @media (max-width: 960px) {
      .detail-info__back {
        font-size: 180px;
        height: 40px; } }
    .detail-info__back a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: #6e7882;
      color: #fff;
      font-size: 1.8rem;
      font-weight: 500;
      letter-spacing: .06em; }
      @media (max-width: 960px) {
        .detail-info__back a {
          font-size: 1.6rem; } }

/* ========
table-form
======== */
.table-form {
  padding: 40px 0 80px; }
  @media (max-width: 960px) {
    .table-form {
      padding: 20px 0 40px; } }
  .table-form table {
    background-color: #fff;
    width: 100%; }
    @media (max-width: 960px) {
      .table-form table {
        background-color: transparent; } }
    .table-form table tr {
      border-top: 1px solid #ececec; }
      @media (max-width: 960px) {
        .table-form table tr {
          display: block;
          border: none;
          background-color: #fff;
          margin-top: 10px; } }
      .table-form table tr:nth-child(1) {
        border-top: none; }
        @media (max-width: 960px) {
          .table-form table tr:nth-child(1) {
            margin-top: 0; } }
      .table-form table tr.-empty {
        background-color: #ffa6b3; }
        .table-form table tr.-empty th {
          background-color: rgba(255, 255, 255, 0.2); }
        .table-form table tr.-empty td {
          background-color: transparent; }
    .table-form table th, .table-form table td {
      font-size: 1.6rem;
      font-weight: 400;
      letter-spacing: .04em;
      text-align: left;
      line-height: 1.8; }
      @media (max-width: 960px) {
        .table-form table th, .table-form table td {
          display: block; } }
    .table-form table th {
      width: 300px;
      padding: 24px 32px;
      vertical-align: top;
      color: #555;
      font-weight: 700; }
      @media (max-width: 960px) {
        .table-form table th {
          width: 100%;
          padding: 8px 16px; } }
      .table-form table th.-required:after {
        content: '※必須';
        font-weight: 400;
        font-size: 1.3rem;
        color: #d90021;
        margin: 0 0 0 1em; }
    .table-form table td {
      padding: 24px;
      vertical-align: middle;
      background-color: #f6f6f6; }
      @media (max-width: 960px) {
        .table-form table td {
          padding: 16px; } }
    .table-form table input, .table-form table textarea {
      width: 100%;
      padding: 4px 8px;
      background-color: #fff;
      border: 1px solid #d0d0d0;
      font-size: 1.6rem; }
    .table-form table textarea {
      min-height: 240px;
      max-height: 480px;
      resize: vertical; }
      @media (max-width: 960px) {
        .table-form table textarea {
          min-height: 120px;
          max-height: 360px; } }
    .table-form table select {
      width: 400px;
      padding: 4px 8px;
      border: 1px solid #d0d0d0;
      font-size: 1.6rem;
      background: #fff;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' overflow='scroll' viewBox='0 0 13.54999 12'%3E%3Cdefs/%3E%3Cpath d='M7.64102 11.5l5.77351-10.00001C13.79942.83333 13.3183 0 12.5485 0H1.00149C.23169 0-.24943.83333.13547 1.49999L5.90897 11.5c.3849.66666 1.34715.66666 1.73205 0z'/%3E%3C/svg%3E");
      background-size: .5em;
      background-repeat: no-repeat;
      background-position: right 12px center; }
      @media (max-width: 960px) {
        .table-form table select {
          width: 100%; } }
  .table-form__privacy {
    background-color: #fff;
    margin: 40px 0;
    width: 100%;
    height: 240px;
    overflow-y: scroll;
    padding: 32px;
    font-size: 1.4rem;
    letter-spacing: .04em;
    line-height: 1.66;
    border: 1px solid #d0d0d0; }
    @media (max-width: 960px) {
      .table-form__privacy {
        padding: 24px; } }
    .table-form__privacy p + p {
      margin-top: .75em; }
  .table-form__foot p {
    text-align: center;
    font-size: 1.4rem; }
    @media (max-width: 960px) {
      .table-form__foot p {
        text-align: left; } }
  .table-form__confirm {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    margin: 20px 0 40px; }
    @media (max-width: 960px) {
      .table-form__confirm {
        padding: 0 10px; } }
    .table-form__confirm label {
      display: flex;
      align-items: center; }
    .table-form__confirm input {
      width: 20px;
      height: 20px;
      flex: 0 0 auto;
      background-color: #fff;
      box-shadow: 0 0 0 1px #d0d0d0;
      margin: 0 .5em 0 0; }
      .table-form__confirm input:checked {
        border: 4px solid #fff;
        background-color: #888; }
  .table-form__submit input {
    width: 320px;
    height: 60px;
    background-color: #a60019;
    color: #fff;
    cursor: pointer;
    letter-spacing: .2em;
    text-align: center;
    margin: 0 auto;
    font-size: 1.8rem; }
    @media (min-width: 961px) {
      .table-form__submit input:hover {
        opacity: .6; } }

/* ========
modules
======== */
.logo-content {
  width: 124px;
  margin: 0 auto 20px; }
  @media (max-width: 960px) {
    .logo-content {
      width: 86px;
      margin-bottom: 16px; } }

/* ========
modules
======== */
/* ========
utility
======== */
.u-mt05 {
  margin-top: .5em; }

.u-mt1 {
  margin-top: 1em; }

.u-mt2 {
  margin-top: 2em; }

.u-mt3 {
  margin-top: 3em; }

.u-mt4 {
  margin-top: 4em; }

.u-mb05 {
  margin-bottom: .5em; }

.u-mb1 {
  margin-bottom: 1em; }

.u-mb2 {
  margin-bottom: 2em; }

.u-mb3 {
  margin-bottom: 3em; }

.u-mb4 {
  margin-bottom: 4em; }

.u-pt05 {
  padding-top: .5em; }

.u-pt1 {
  padding-top: 1em; }

.u-pt2 {
  padding-top: 2em; }

.u-pt3 {
  padding-top: 3em; }

.u-pt4 {
  padding-top: 4em; }

.u-pb05 {
  padding-bottom: .5em; }

.u-pb1 {
  padding-bottom: 1em; }

.u-pb2 {
  padding-bottom: 2em; }

.u-pb3 {
  padding-bottom: 3em; }

.u-pb4 {
  padding-bottom: 4em; }

.u-white {
  color: #fff;
  fill: #fff; }
  .u-white * {
    color: #fff;
    fill: #fff; }

.u-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto; }

.u-right {
  text-align: right;
  margin-left: auto; }

/*--==========================
  page
============================--*/
/* ========
top
======== */
@media (max-width: 960px) {
  .home .gnav__logo {
    width: 150px;
    height: 150px; } }

/* ========
top
======== */
@media (min-width: 961px) {
  .page-thanks .unit-text-base {
    min-height: calc(100vh - 440px); } }
