/* ========
unit-modal
======== */

.unit-modal{
  position: fixed;
  z-index: 1501;
  width: 800px;
  height: 450px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  @include mq(sp){
    width: 90%;
    max-width: 540px;
    max-height: 260px;
  }
  iframe{
    display: block;
    width: 100%;
    height: 100%;
  }
  &__close{
    display: block;
    position: absolute;
    top: -48px;
    right: -48px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    @include mq(sp){
      right: 0;
    }
    &:before, &:after{
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
    &:before{
      transform: rotate(45deg);
    }
    &:after{
      transform: rotate(-45deg);
    }
  }
}

.screen-overlay{
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#00060a,.8);
  z-index: 1500;
}