/* ========
unit-icon-content
======== */

.unit-icon-content{
  position: relative;
  z-index: 0;
  padding: 20px;
  @include mq(sp) {
    padding: 24px $sp-padding;
  }
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    min-width: $pc-min-width;
    background-color: #e6e6e6;
    z-index: -1;
  }
  .l-inner{
    background-color: #fff;
    padding: 50px 0 60px;
    @include mq(sp) {
      padding: 40px 0;
    }
  }
  &__list{
    margin: 32px 0 0;
    >ul{
      display: flex;
      justify-content: center;
      @include mq(sp) {
        display: block;
      }
      >li{
        width: 312px;
        padding: 0 26px;
        @include mq(sp) {
          width: 100%;
        }
        &:nth-child(n+2){
          border-left: 1px solid #e6e6e6;
          @include mq(sp) {
            border-left: none;
            margin-top: 32px;
          }
        }
        .desc-s{
          text-align: center;
          margin-top: 0;
          &:before{
            content: '';
            display: block;
            width: 1px;
            height: 8px;
            background-color: palettes(main);
            margin: 8px auto;
          }
        }
        ul{
          margin: 20px 0 0;
        }
        li{
          &:nth-child(n+2){
            // padding: 14px 0 0;
            margin: 16px 0 0;
            // border-top: 1px solid #e6e6e6;
          }
          p{
            margin-left: 1.66em;
            text-indent: -1.66em;
            font-size: 1.3rem;
            font-weight: 400;
            letter-spacing: .06em;
            line-height: 1.5;
            &:before{
              content: '';
              display: inline-block;
              width: 15px;
              height: 15px;
              background: transparent url(../images/service/icon_check.png) center center no-repeat;
              background-size: cover;
              margin: 0 .5em 0 0;
              transform: translate(0,3px);
            }
          }
        }
      }
    }
  }
  &__icon{
    background-color: #00192d;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 16px;
    @include mq(sp) {
      width: 120px;
      height: 120px;
    }
    img{
      height: 48%;
    }
  }
}