/* ========
list-text
======== */

.list-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  ul{
    li{
      margin-left: 2em;
      text-indent: -2em;
      &:before{
        content: 'ー、';
        color: palettes(main);
      }
    }
  }
  ol{
    counter-reset: listText;
    li{
      margin-left: 1.25em;
      text-indent: -1.25em;
      &:before{
        counter-increment: listText 1;
        content: counter(listText)'. ';
        color: palettes(main);
      }
    }
  }
  li{
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: .06em;
    line-height: 2.1;
    @include mq(sp) {
      line-height: 1.66;
    }
    & + li{
      @include mq(sp) {
        margin-top: .6em;
      }
    }
  }
}