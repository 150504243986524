/* ========
box-contact-link
======== */

.box-contact-link{
  background: transparent url(../images/common/bg_contact.jpg) center center no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  padding: 80px 0;
  @include mq(sp) {
    padding: 40px $sp-padding;
  }
  &__ttl{
    line-height: 1;
    margin: 0 0 24px;
    &__en{
      font-family: $century;
      font-size: 4.3rem;
      letter-spacing: .1em;
      font-weight: 500;
      @include mq(sp) {
        font-size: 3.8rem;
      }
    }
    &__ja{
      font-family: $serif;
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: .1em;
      @include mq(sp) {
        font-size: 1.3rem;
      }
    }
  }
  .btn-basic{
    margin: 40px auto 0;
    @include mq(sp) {
      margin-top: 24px;
    }
  }
}