///*------------------------------
// 変数
// -------------------------------*/
//色
$color_text: #000;
$color_main: #a60019;
$color_link: #000;
$color_blue: #0078fd;
$color_green: #00be5a;
$color_orange: #ff8a00;

//カラーバリエーション
$palettes: (
  main: (
    base: $color_main,
    light: lighten($color_main, 10%),
    dark: #6e0000,
    lightest: lighten($color_main, 50%),
  ),
  blue: (
    base: $color_blue,
    light: lighten($color_blue, 10%),
    dark: darken($color_blue, 10%),
    lightest: lighten($color_blue, 50%),
  ),
  green: (
    base: $color_green,
    light: lighten($color_green, 10%),
    dark: darken($color_green, 10%),
    lightest: lighten($color_green, 50%),
  ),
  orange: (
    base: $color_orange,
    light: lighten($color_orange, 10%),
    dark: darken($color_orange, 10%),
    lightest: lighten($color_orange, 50%),
  ),
);

//font
$gothic: 'Noto Sans JP', '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', 'YuGothic', 'ヒラギノ角ゴ Pro W3', 'メイリオ', sans-serif;
$serif: 'Noto Serif JP', '游明朝' , 'Yu Mincho' , '游明朝体' , 'YuMincho' , 'ヒラギノ明朝 Pro W3' , 'Hiragino Mincho Pro' , 'HiraMinProN-W3' , 'HGS明朝E' , 'ＭＳ Ｐ明朝' , 'MS PMincho' , serif;
$century: 'Abhaya Libre', serif;

// レイアウト
$pc-side-width: 210px;
$pc-min-width: 990px + $pc-side-width;
$sp-min-width: 320px;
$pc-padding: 40px;
$sp-padding: 10px;

//breakpoint記述
//@include mq(sp){width:100%;}
$bp-sp: 960px !default;