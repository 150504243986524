/* ========
intro-area
======== */

.intro-area{
  padding: 96px 0;
  background: transparent url(../images/common/bg_logo.png) right 0 bottom -214px no-repeat;
  background-size: 576px;
  position: relative;
  z-index: 0;
  @include mq(sp) {
    padding: 48px $sp-padding+10px;
    background-size: 278px;
    background-position: right -20px bottom -40px;
  }
}