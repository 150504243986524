/* ========
unit-text-base
======== */

.unit-text-base{
  padding: 80px 0;
  @include mq(sp) {
    padding: 40px 0;
  }
  .l-inside{
    padding: 0 40px;
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: .06em;
    @include mq(sp) {
      padding: 0 $sp-padding;
    }
  }
  &__grp{
    &:nth-child(n+2){
      margin-top: 80px;
      @include mq(sp) {
        margin-top: 40px;
      }
    }
  }
  h1,h2{
    font-size: 2.3rem;
    font-weight: 500;
    letter-spacing: .06em;
    border-left: 3px solid palettes(main);
    line-height: 1.4;
    padding: 0 0 0 .75em;
    margin: 2em 0 1em;
    @include mq(sp) {
      font-size: 1.8rem;
    }
  }
  h3{
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: .06em;
    color: palettes(main);
    line-height: 1.7;
    margin: 1em 0;
    @include mq(sp) {
      font-size: 1.6rem;
    }
  }
  h4{
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: .06em;
    @include mq(sp) {
      font-size: 1.5rem;
    }
  }
  h5{
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: .06em;
    @include mq(sp) {
      font-size: 1.4rem;
    }
  }
  h6{
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .06em;
    @include mq(sp) {
      font-size: 1.3rem;
    }
  }
  i,em{
    font-family: $serif;
  }
  ul{
    list-style-type: disc;
    padding: 0 0 0 2em;
    margin: 1em 0;
    li{
      list-style-type: disc;
    }
  }
  ol{
    list-style-type: decimal;
    padding: 0 0 0 2em;
    margin: 1em 0;
    li{
      list-style-type: decimal;
    }
  }
  a{
    // text-decoration: underline;
    // color: #0064b4;
    // @include mq(pc) {
    //   &:hover{
    //     opacity: 1;
    //     text-decoration: underline;
    //   }
    // }
  }
  p{
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: .06em;
    line-height: 1.8;
    margin: 1em 0;
  }
  blockquote{
    color: #505050;
    background-color: #f6f6f6;
    padding: 24px 32px;
    margin: 10px 0;
    @include mq(sp) {
      padding: 16px;
    }
  }
}