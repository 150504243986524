/* ========
slider-movie
======== */

.slider-movie{
  width: 496px;
  margin: 0 auto;
  @include mq(sp) {
    width: 100%;
  }
  .slick-list{
    overflow: visible !important;
  }
  &__item{
    width: 480px;
    position: relative;
    z-index: 0;
    background-color: #00192d;
    margin: 0 8px;
    cursor: pointer;
    img{
      opacity: .8;
    }
    &:after{
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100px;
      height: 100px;
      margin: auto;
      background: transparent center center no-repeat;
      background-size: contain;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' overflow='scroll' viewBox='0 0 200 200'%3E%3Cdefs/%3E%3Cstyle%3E.tri{fill:%23fff}%3C/style%3E%3Cpath d='M100 4c52.93457 0 96 43.06543 96 96s-43.06543 96-96 96-96-43.06543-96-96S47.06543 4 100 4m0-4C44.77153 0 0 44.77153 0 100s44.77153 100 100 100 100-44.77151 100-100S155.22845 0 100 0z' class='tri'/%3E%3Cpath d='M145.66878 96.23975L77.05927 56.46854c-2.65662-1.53998-5.98344.37689-5.98344 3.4476v80.11757c0 3.0818 3.34844 4.99722 6.00495 3.43504l68.60951-40.34637c2.62946-1.54626 2.61756-5.35283-.02151-6.88263z' class='tri'/%3E%3C/svg%3E");
      z-index: 2;
      @include mq(sp) {
        width: 64px;
        height: 64px;
      }
    }
  }
  &__desc{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#00192d,.2);
    z-index: 1;
    color: #fff;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .06em;
    padding: 8px 16px;
    @include mq(sp) {
      font-size: 1.3rem;
    }
  }
  .slick-dots{
    display: flex;
    justify-content: center;
    margin: 24px 0 0;
    li{
      cursor: pointer;
      display: block;
      position: relative;
      padding: 2px;
      margin: 0 10px;
      @include mq(sp) {
        margin: 0 4px;
      }
      &.slick-active{
        &:before{
          background-color: #00192d;
        }
      }
      &:before{
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        background-color: #bac0c4;
        border-radius: 50%;
        transition: .2s ease;
        @include mq(sp) {
          width: 10px;
          height: 10px;
        }
      }
      button{
        display: none;
      }
    }
  }
}