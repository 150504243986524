/* ========
title
======== */

.ttl-type01{
  font-family: $century;
  font-size: 5rem;
  font-weight: 500;
  letter-spacing: .08em;
  line-height: 1.4;
  text-align: center;
  color: #00192d;
  margin: 0 0 12px;
  @include mq(sp) {
    font-size: 4.3rem;
  }
  &:after{
    content: '';
    display: block;
    width: 3px;
    height: 25px;
    background-color: palettes(main);
    margin: 0 auto;
    @include mq(sp) {
      height: 20px;
    }
  }
}

.ttl-type02{
  text-align: center;
  line-height: 1.2;
  margin: 0 0 24px;
  &__en{
    font-family: $century;
    font-size: 5rem;
    font-weight: 500;
    letter-spacing: .08em;
    color: #00192d;
    @include mq(sp) {
      font-size: 3.6rem;
    }
  }
  &__ja{
    font-family: $serif;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: .1em;
  }
}

.ttl-type03{
  font-family: $serif;
  font-size: 2.9rem;
  font-weight: 600;
  letter-spacing: .1em;
  line-height: 1.4;
  text-align: center;
  color: #00192d;
  margin: 0 0 12px;
  @include mq(sp) {
    font-size: 2rem;
  }
  &:after{
    content: '';
    display: block;
    width: 3px;
    height: 20px;
    background-color: palettes(main);
    margin: 12px auto 0;
    @include mq(sp) {
      height: 15px;
    }
  }
}