/* ========
lead-service
======== */

.lead-service{
  padding: 80px 0 90px;
  position: relative;
  z-index: 0;
  @include mq(sp) {
    padding: 40px 0 50px;
  }
  &.-content{
    padding: 40px 0;
  }
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    min-width: $pc-min-width;
    background-color: #00192d;
    z-index: -1;
  }
  .l-inner{
    > .btn-basic{
      margin: 30px auto 0;
      @include mq(sp) {
        margin: 20px auto 0;
        width: calc(100% - 50px);
        height: 70px;
      }
    }
  }
}