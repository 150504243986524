/* ========
modules
======== */

.logo-content{
  width: 124px;
  margin: 0 auto 20px;
  @include mq(sp) {
    width: 86px;
    margin-bottom: 16px;
  }
}